import { AVAILABLE_AD_UNITS } from 'constants/common';
import moment from 'moment-timezone';

export const isJsonString = str => {
  if (str) {
    try {
      return JSON.parse(str);
    } catch (e) {
      return null;
    }
  } else {
    return null;
  }
};

export function rangeDesc(start, end) {
  return Array(start - end + 1)
    .fill()
    .map((_, idx) => start - idx);
}

export const today = moment().tz('Asia/Ho_Chi_Minh').format('YYYY-MM-DD');

export const formatDate = (date, format = 'MM/DD/YYYY') => moment(date).format(format);

export const getInitFilerChart = () => ({
  startDate: new Date(formatDate(moment(new Date()).subtract(6, 'days'))),
  endDate: new Date(formatDate(new Date())),
});

export const downloadFile = (file, name) => {
  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', `${name}.xlsx`);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const getDomainUrl = (url) => {
  const matchResult = url?.match(/^https?:\/\/([a-zA-Z0-9.-]+)\.com$/);
  if (matchResult && matchResult.length === 2) {
    return matchResult[1];
  }
}

export const getUrlWebsite = (domain) => {
  return 'https://' + domain + '.com'
}

export const isObjectNameInArray = (targetName, array, key) => array?.some(element => element[key] === targetName);

export const convertDate = dateString => {
  const date = new Date(moment(dateString).add(1, 'days'));;
  date.setHours(0, 0, 0, 0);
  return date.toISOString().split("T")[0] + "T00:00:01.000Z";
};

export const convertDateEndDay = dateString => {
  const date = new Date(dateString);
  date.setHours(23, 59, 59, 999);
  return date;
};

export const renderScript = banner => {
  if (banner?.adUnit === AVAILABLE_AD_UNITS.NATIVE_BANNER)
    return `<script async="async" data-cfasync="false" src="${banner?.script}"></script><div id="container-${banner?._id}"></div>`;
  if (banner?.adUnit.includes('BANNER'))
    return `<script type="text/javascript">
              atOptions = {
                'key' : ${banner?._id},
                'format' : 'iframe',
                'height' : ${banner?.height},
                'width' : ${banner?.width},
                'params' : {}
              };
              document.write('<scr' + 'ipt type="text/javascript" src="${banner?.script}"></scr' + 'ipt>');
            </script>`;
  if (
    [
      AVAILABLE_AD_UNITS.POP_UNDER,
      AVAILABLE_AD_UNITS.SOCIAL_BAR,
      AVAILABLE_AD_UNITS.INTERSTITIAL,
      AVAILABLE_AD_UNITS.DIRECT_LINK,
    ].includes(banner?.adUnit)
  )
    return `<script async="async" data-cfasync="false" type='text/javascript' src="${banner?.script}"`;
};

export function currencyFormat(x) {
  if (!x) {
    return '0 VND';
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' VND';
}
