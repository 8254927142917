import React from 'react';
import { Controller } from 'react-hook-form';
import { Box, FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';

const SelectController = ({
  name,
  label,
  control,
  isReadOnly = false,
  styleContainer,
  styleBoxInput,
  isRequired,
  placeholder,
  options,
  styleLabel,
  styleInput,
  requiredIndicator,
  onChange,
  onBlur,
  focusBorderColor = '#1DCA74',
  size = 'lg',
  ...props
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field, fieldState: { error } }) => (
      <FormControl {...styleContainer} isRequired={isRequired} isInvalid={error && error?.message}>
        {label && (
          <FormLabel textColor={error && '#FF3300'} requiredIndicator={requiredIndicator} {...styleLabel} minW="150px">
            {label}
          </FormLabel>
        )}
        <Box {...styleBoxInput} position="relative" borderRadius="12px">
          <Select
            focusBorderColor={focusBorderColor}
            size={size}
            _placeholder={{ opacity: 1, color: 'gray.300' }}
            {...field}
            {...props}
            onChange={e => {
              field.onChange(e);
              onChange?.(e);
            }}
            onBlur={e => {
              field.onBlur(e);
              onBlur?.(e);
            }}
            isReadOnly={isReadOnly}
            useBasicStyles
            options={options}
            placeholder={placeholder || 'Chọn'}
            chakraStyles={{
              input: (provided, state) => ({
                ...provided,
                zIndex: 10,
                // minHeight: '200px'
                // paddingTop: '28px',
                // paddingBottom: '0px',
                // borderRadius: '24px',
                ...styleInput,
              }),
              placeholder: (provided, state) => ({
                ...provided,
                fontSize: '14px',
              }),
              singleValue: (provided, state) => ({
                ...provided,
                fontSize: '14px',
              }),
              option: (provided, state) => ({
                ...provided,
                fontSize: '14px',
              }),
            }}
          />
          <FormErrorMessage>{error && error?.message}</FormErrorMessage>
        </Box>
      </FormControl>
    )}
  />
);

export default SelectController;
