import React, { useEffect, useState } from 'react';
import { Button, Center, Flex, FormControl, FormLabel, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import Pagination from 'components/Pagination/Pagination';
import { Select } from 'chakra-react-select';
import { AdUnitsOptions, GROUP_BY_WEBSITE_ADS, GroupByOptions, TypeTicket } from 'constants/common';
import { useQueryGetStatisticsWebsite } from 'services/statistics';
import DatePicker from 'components/DatePicker/DatePicker';
import { convertDate, convertDateEndDay, convertDateStartDay, formatDate, getInitFilerChart } from 'utils/helpers';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import StatisticsTable from './components/Table';
import { useQueryGetMyWebsiteAdUnits, useQueryGetMyWebsites } from 'services/website';
import { mappingOptionSelect } from 'utils/mapping';
import { useQueryGetCountry } from 'services/campaign';
import { CookieStorage } from 'utils/cookie-storage';
import { useHistory } from 'react-router-dom';

const initFiler = {
  startDate: new Date(formatDate(new Date())),
  endDate: new Date(formatDate(new Date())),
};

export const initialFilter = {
  pageSize: 10,
  pageIndex: 0,
};

function Statistics() {
  const isLoggedIn = CookieStorage.isAuthenticated();
  const history = useHistory();
  const textColor = useColorModeValue('gray.700', 'white');
  const [filter, setFilter] = useState({ ...initialFilter });
  const [statisticFilter, setStatisticFilter] = useState({
    domain: null,
    country: null,
    type: null,
  });
  const [tab, setTab] = useState(GROUP_BY_WEBSITE_ADS.DATE);

  useEffect(() => {
    if (!isLoggedIn) {
      return history.push('/auth/sign-in');
    }
  }, [isLoggedIn, history]);

  const { data: myWebsites } = useQueryGetMyWebsites({}, { enabled: isLoggedIn });
  const { data: country } = useQueryGetCountry({}, { enabled: isLoggedIn });
  const { data } = useQueryGetStatisticsWebsite(
    {
      ...filter,
      ...(tab && { groupBy: tab }),
    },
    { enabled: isLoggedIn }
  );

  const onReset = () => {
    setFilter({
      ...initialFilter,
    });
    setStatisticFilter({
      domain: null,
      country: null,
      type: null,
    });
  };

  const onFilter = () => {
    setFilter({
      ...filter,
      ...(!!statisticFilter?.startDate && { startDate: moment(statisticFilter?.startDate).startOf('day').toString() }),
      ...(!!statisticFilter?.endDate && { endDate: moment(statisticFilter?.endDate).endOf('day').toString() }),
      domain: statisticFilter?.domain?.value,
      country: statisticFilter?.country?.value,
      type: statisticFilter?.type?.value,
    });
  };

  const onChangeDate = type => date => {
    setStatisticFilter(prev => ({
      ...prev,
      [type]: date,
    }));
  };
  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
      <Card p="16px" mb="24px" bg="#fff">
        <CardHeader p="12px 5px" mb="20px">
          <Flex justifyContent={'space-between'}>
            <Flex direction={'column'}>
              {/* <Flex direction="column" gap={'30px'}>
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Số liệu thống kê
                </Text>
              </Flex> */}
              <Flex justifyContent={'space-between'} alignItems={'end'} gap={'20px'}>
                <Stack>
                  <Flex alignItems={'center'} gap={'20px'} flexWrap={{ base: 'wrap', lg: 'nowrap' }}>
                    <FormControl minWidth={{ base: 'full', sm: '300px' }}>
                      <FormLabel>Quốc gia</FormLabel>
                      <Select
                        isClearable
                        menuShouldBlockScroll
                        value={statisticFilter?.country || null}
                        placeholder="Chọn"
                        onChange={e => {
                          setStatisticFilter(prev => ({
                            ...prev,
                            country: e,
                          }));
                        }}
                        options={mappingOptionSelect(country?.data, 'name', 'code')}
                      ></Select>
                    </FormControl>
                    <FormControl minWidth={{ base: 'full', sm: '300px' }}>
                      <FormLabel>Website</FormLabel>
                      <Select
                        isClearable
                        menuShouldBlockScroll
                        value={statisticFilter?.domain || null}
                        placeholder="Chọn"
                        onChange={e => {
                          setStatisticFilter(prev => ({
                            ...prev,
                            domain: e,
                          }));
                        }}
                        options={mappingOptionSelect(myWebsites?.data)}
                      ></Select>
                    </FormControl>
                    <FormControl minWidth={{ base: 'full', sm: '300px' }}>
                      <FormLabel>Đơn vị quảng cáo</FormLabel>
                      <Select
                        isClearable
                        menuShouldBlockScroll
                        value={statisticFilter?.type || null}
                        placeholder="Chọn"
                        onChange={e => {
                          setStatisticFilter(prev => ({
                            ...prev,
                            type: e,
                          }));
                        }}
                        options={AdUnitsOptions}
                      ></Select>
                    </FormControl>
                  </Flex>
                  <Flex flexWrap="wrap" marginTop={4} gap={'20px'}>
                    <FormControl display="flex" flexDirection={'column'} width={{ base: 'full', sm: '300px' }}>
                      <FormLabel m="0">Ngày bắt đầu</FormLabel>
                      <DatePicker selectedDate={statisticFilter.startDate} onChange={date => onChangeDate('startDate')(date)} />
                    </FormControl>
                    <FormControl display="flex" flexDirection={'column'} width={{ base: 'full', sm: '300px' }}>
                      <FormLabel m="0">Ngày kết thúc</FormLabel>
                      <DatePicker selectedDate={statisticFilter.endDate} onChange={date => onChangeDate('endDate')(date)} />
                    </FormControl>
                    <Button
                      backgroundColor="white"
                      color="blue.400"
                      border="1px"
                      _hover={{
                        text: 'white',
                      }}
                      maxH="30px"
                      mx="0px"
                      fontSize="14px"
                      alignSelf={'end'}
                      px={8}
                      onClick={onReset}
                    >
                      Đặt lại
                    </Button>
                    <Button variant="primary" maxH="30px" px={8} fontSize={14} alignSelf={'end'} onClick={onFilter}>
                      Lọc
                    </Button>
                  </Flex>
                  {/* <Flex alignItems={'center'} justifyContent={'space-between'} mt={4} flexWrap={{ base: 'wrap', lg: 'nowrap' }}>
                    <Text>Nhóm theo:</Text>
                    <Flex
                      fontSize={13}
                      color="#747474"
                      fontWeight={500}
                      bg={'white'}
                      border={'1px solid #ccc'}
                      rounded={6}
                      overflow={'hidden'}
                      mt={4}
                    >
                      {GroupByOptions.map((item, index) => (
                        <Center
                          key={index}
                          px={4}
                          py={2}
                          bg={item.value === tab ? 'blue.100' : 'white'}
                          color={item.value === tab ? 'blue.500' : '#747474'}
                          cursor={'pointer'}
                          onClick={() => setTab(item.value)}
                        >
                          <Text>{item.label}</Text>
                        </Center>
                      ))}
                    </Flex>
                  </Flex> */}
                </Stack>
              </Flex>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody>
          <Stack overflow={'auto'} mt={4}>
            <StatisticsTable data={data?.data || []} title={tab} />
          </Stack>
          {!isEmpty(data?.data) && (
            <Flex justifyContent={'flex-end'}>
              <Pagination
                page={data?.pagination?.page}
                pageLength={data?.pagination?.pageSize}
                totalRecords={data?.pagination?.count}
                onPageChange={(page, pageLength) => {
                  setFilter({
                    ...filter,
                    pageSize: pageLength,
                    pageIndex: page - 1,
                  });
                }}
              />
            </Flex>
          )}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default Statistics;
