import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Flex,
  Stack,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Button,
  useColorModeValue,
  Td,
  Box,
  CardBody,
  Card as CardChakra,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import { CookieStorage } from 'utils/cookie-storage';
import { StorageKeys } from 'constants/storage-keys';
import { PUBLISHER_PAGE_URL, ROOT_APP } from 'constants/common';
import { useQueryListReferral } from 'services/referrals';
import InputSearch from 'components/InputSearch/InputSearch';
// import ChooseBanner from './components/ChooseBanner';
// import { useQueryGetAppSetting } from 'services/referrals';
import Pagination from 'components/Pagination/Pagination';
import Row from './components/Row';
import { CopyIcon } from '@chakra-ui/icons';
import useCopyToClipboard from 'hooks/use-copy-to-clipboard';
import { toast } from 'components/Toast';

export const initialFilter = {
  pageSize: 10,
  pageIndex: 0,
};

function Referrals() {
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const user = CookieStorage.getCookieData(StorageKeys.UserInfo);
  const history = useHistory();
  const [filter, setFilter] = useState(initialFilter);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [, copy] = useCopyToClipboard();

  const { data, isLoading, refetch } = useQueryListReferral({
    ...filter,
  });

  const onClearSearch = () => {
    setFilter(initialFilter);
    setSearchKeyword('');
  };

  const onChangeSearch = event => {
    event.persist();
    setSearchKeyword(event.target.value);
  };

  const onFilter = () => {
    setFilter({
      ...filter,
      searchKeyword,
    });
  };
  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
      {user?.referral && (
        <CardChakra bgColor="white" mb="22px">
          <CardBody>
            <Stack>
              <Text fontWeight="700" textColor="#333">
                Liên kết giới thiệu
              </Text>
              <Flex gap={3}>
                <Text textColor="blue.400">{`${PUBLISHER_PAGE_URL}/#/auth/sign-up?ref=${user?.referral}`}</Text>
                <CopyIcon
                  boxSize={6}
                  cursor="pointer"
                  onClick={() => {
                    copy(`${PUBLISHER_PAGE_URL}/#/auth/sign-up?ref=${user?.referral}`);
                    toast.showMessageSuccess('Copy thành công.');
                  }}
                />
              </Flex>
            </Stack>
          </CardBody>
        </CardChakra>
      )}

      <Card p="16px" mb="24px" bg="#fff">
        <Flex justifyContent={'space-between'}>
          <Flex flexDirection={'column'}>
            {/* <Flex direction="column">
              <Text fontSize="xl" color={textColor} fontWeight="bold">
                Giới thiệu
              </Text>
            </Flex> */}
            <Flex justifyContent={'space-between'} alignItems={'end'} gap={'20px'}>
              <Stack>
                <Flex flex="1" gap={'16px'}>
                  <FormControl>
                    <FormLabel>Tìm kiếm theo tên, email</FormLabel>
                    <InputSearch value={searchKeyword} onChange={onChangeSearch} onClearSearch={onClearSearch} />
                  </FormControl>
                </Flex>
              </Stack>
              <Button variant="primary" maxH="30px" fontSize="14px" onClick={onFilter} alignSelf={'end'}>
                Lọc
              </Button>
            </Flex>
          </Flex>
        </Flex>
        <Table variant="simple" color={textColor} overflowX="auto" mt={10}>
          <Thead>
            <Tr my=".8rem" pl="0px" color="gray.400">
              <Th pl="0px" borderColor={borderColor} color="gray.400">
                Tên
              </Th>
              <Th borderColor={borderColor} color="gray.400">
                Email
              </Th>
              <Th borderColor={borderColor} color="gray.400">
                Số dư
              </Th>
              <Th borderColor={borderColor} color="gray.400">
                Mã giới thiệu
              </Th>
              {/* <Th borderColor={borderColor} color="gray.400">
                Số điện thoại
              </Th>
              <Th borderColor={borderColor} color="gray.400">
                Kênh liên lạc
              </Th> */}
              <Th borderColor={borderColor} color="gray.400">
                Trạng thái xác thực thông tin
              </Th>
              <Th borderColor={borderColor} color="gray.400">
                Ngày Cập nhật
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {data?.data?.map((row, index, arr) => {
              return <Row key={row._id} directLinkDetail={row} isLast={index === arr.length - 1 ? true : false} refetch={refetch} />;
            })}
            {isEmpty(data?.data) && !isLoading && (
              <Tr>
                <Td colSpan="8">
                  <Box textAlign="center" height="200px" pt="24px">
                    Không có dữ liệu
                  </Box>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
        <Flex justifyContent={'flex-end'}>
          <Pagination
            page={data?.pagination?.page}
            pageLength={data?.pagination?.pageSize}
            totalRecords={data?.pagination?.count}
            onPageChange={(page, pageLength) => {
              setFilter({
                ...filter,
                pageSize: pageLength,
                pageIndex: page - 1,
              });
            }}
          />
        </Flex>
      </Card>
      {/* <ChooseBanner informationBanner={data?.data} /> */}
    </Flex>
  );
}

export default Referrals;
