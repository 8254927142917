import React, { useState } from 'react';
import { Box, Button, Radio, Flex, Grid, GridItem, Heading, Image, Link, Text, RadioGroup, FormErrorMessage } from '@chakra-ui/react';
import { useHistory, Link as LinkRoute, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import queryString from 'query-string';

import InputCustom from 'components/Form/InputCustom';
import SelectController from 'components/Form/SelectController';
import { ContactChannelOptions } from 'constants/common';
import { useRegisterMutation } from 'services/user';
import { toast } from 'components/Toast';
import { RegisterFormValidate } from 'utils/validation';
import bg_register from '../../assets/img/bg_register_ad.png';
import { FiEye } from 'react-icons/fi';
import { FiEyeOff } from 'react-icons/fi';
import { ADVERTISER_PAGE_URL, CountryOptions } from 'constants/common';

function SignUp() {
  const history = useHistory();
  const registerMutation = useRegisterMutation();
  const { search } = useLocation();
  const params = queryString.parse(search);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConf, setShowPasswordConf] = useState(false);
  const [checkPolicy, setCheckPolicy] = useState('0');
  const [errorPolicy, setErrorPolicy] = useState(null);

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(RegisterFormValidate),
    defaultValues: {
      username: '',
      email: '',
      password: '',
      passwordConf: '',
      // contactChannel: undefined,
      // contactUsername: '',
      // countryCode: undefined,
      phone: '',
    },
  });

  const onSubmit = values => {
    if (checkPolicy !== '1') {
      setErrorPolicy('Vui lòng đồng ý các điều khoản');
      return;
    }

    registerMutation.mutate(
      { ...values, countryCode: values?.countryCode?.value, contactChannel: values?.contactChannel?.value, referralBy: params?.ref },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Đăng ký tài khoản thành công. Vui lòng xác thực tài khoản.');
          history.push('/sign-in');
        },
      }
    );
  };

  return (
    <Box minH="100vh" maxW="1200px" mx={{ base: '20px', lg: 'auto' }} marginY="64px">
      <div>
        <Grid templateColumns="repeat(12, 1fr)" gap={{ base: '0', lg: '40px' }}>
          <GridItem w="100%" colSpan={{ base: '12', lg: '5' }}>
            <Box>
              <Flex justifyContent="center" alignItems="center" mb="64px">
                <Link
                  href={ADVERTISER_PAGE_URL}
                  paddingX="24px"
                  paddingY="16px"
                  border="1px"
                  borderColor="#1DCA74"
                  bgColor="#fff"
                  textColor="#1DCA74"
                  fontSize="18px"
                  fontWeight="600"
                  borderTopLeftRadius="10px"
                  borderBottomLeftRadius="10px"
                >
                  Advertiser
                </Link>
                <Box
                  paddingX="24px"
                  paddingY="16px"
                  border="1px"
                  borderColor="#1DCA74"
                  bgColor="#1DCA74"
                  textColor="white"
                  fontSize="18px"
                  fontWeight="600"
                  borderTopRightRadius="10px"
                  borderBottomRightRadius="10px"
                >
                  Publisher
                </Box>
              </Flex>
              <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <Heading as="h3" fontSize="24px" mb={18} color="#666666">
                  Đăng kí tài khoản Publisher
                </Heading>
                <InputCustom
                  control={control}
                  name="email"
                  label="Email"
                  placeholder="Email"
                  styleBoxInput={{ flex: 1 }}
                  isRequired
                  extendsComponent={
                    <Box textColor="#9E9E9E" mt="10px">
                      <Text fontSize="13px">Tối đa 255 kí tự.</Text>
                      <Text fontSize="13px">Chỉ bao gồm chữ hoa, chữ thường, số và kí tự</Text>
                      <Text fontSize="13px" color="#1DCA74">
                        Không bắt đầu và kết thúc bằng dấu chấm.
                      </Text>
                    </Box>
                  }
                />
                <InputCustom
                  control={control}
                  name="username"
                  label="Tên đăng nhập"
                  placeholder="Tên đăng nhập"
                  styleBoxInput={{ flex: 1 }}
                  isRequired
                  extendsComponent={
                    <Box textColor="#9E9E9E" mt="10px">
                      <Text fontSize="13px">Tối thiểu 6 kí tự.</Text>
                      <Text fontSize="13px">Chỉ bao gồm chữ thường, số và dấu chấm ".".</Text>
                      <Text fontSize="13px" color="#1DCA74">
                        Không bắt đầu và kết thúc bằng dấu chấm.
                      </Text>
                    </Box>
                  }
                />
                <Box position="relative">
                  <InputCustom
                    control={control}
                    name="password"
                    label="Mật khẩu"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Mật khẩu"
                    styleBoxInput={{ flex: 1 }}
                    isRequired
                    extendsComponent={
                      <Box textColor="#9E9E9E" mt="10px">
                        <Text fontSize="13px">Tối thiểu 8 kí tự.</Text>
                        <Text fontSize="13px" color="#1DCA74">
                          Chỉ bao gồm chữ hoa, chữ thường, số và ký tự đặc biệt
                        </Text>
                      </Box>
                    }
                  />
                  <Box onClick={() => setShowPassword(!showPassword)} position="absolute" zIndex="10" right="4" top="45px" cursor="pointer">
                    {showPassword ? <FiEyeOff size={24} color="#1DCA74" /> : <FiEye size={24} color="#1DCA74" />}
                  </Box>
                </Box>
                <Box position="relative">
                  <InputCustom
                    control={control}
                    type={showPasswordConf ? 'text' : 'password'}
                    name="passwordConf"
                    label="Xác nhận mật khẩu"
                    placeholder="Xác nhận mật khẩu"
                    styleBoxInput={{ flex: 1 }}
                    isRequired
                    extendsComponent={
                      <Box textColor="#9E9E9E" mt="10px">
                        <Text fontSize="13px">Phải trùng với mật khẩu.</Text>
                        <Text fontSize="13px">Tối thiểu 8 kí tự.</Text>
                        <Text fontSize="13px" color="#1DCA74">
                          Chỉ bao gồm chữ hoa, chữ thường, số và ký tự đặc biệt
                        </Text>
                      </Box>
                    }
                  />
                  <Box
                    onClick={() => setShowPasswordConf(!showPasswordConf)}
                    position="absolute"
                    zIndex="10"
                    right="4"
                    top="45px"
                    cursor="pointer"
                  >
                    {showPasswordConf ? <FiEyeOff size={24} color="#1DCA74" /> : <FiEye size={24} color="#1DCA74" />}
                  </Box>
                </Box>
                {/* <SelectController
                  control={control}
                  name="contactChannel"
                  // label="Kênh liên lạc"
                  placeholder="Chọn kênh liên lạc"
                  options={ContactChannelOptions}
                  styleContainer={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}
                  styleBoxInput={{ flex: 1 }}
                  // isRequired
                  styleInput={{ paddingTop: '28px', paddingBottom: '0px', borderRadius: '24px' }}
                />
                <InputCustom
                  control={control}
                  name="contactUsername"
                  label="Tài khoản liên lạc"
                  placeholder="Nhập tài khoản liên lạc"
                  styleBoxInput={{ flex: 1 }}
                  // isRequired
                /> */}
                {/* <SelectController
                  control={control}
                  placeholder="Chọn quốc gia"
                  name="countryCode"
                  // label="Quốc gia"
                  options={CountryOptions}
                  styleContainer={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}
                  styleBoxInput={{ flex: 1 }}
                  styleInput={{ paddingTop: '28px', paddingBottom: '0px', borderRadius: '24px' }}
                /> */}
                <InputCustom
                  control={control}
                  name="phone"
                  label="Điện thoại"
                  placeholder="Nhập số điện thoại"
                  styleBoxInput={{ flex: 1 }}
                  isRequired
                  extendsComponent={
                    <Box textColor="#9E9E9E" mt="10px">
                      <Text fontSize="13px" color="#1DCA74">
                        Bao gồm 10 chữ số.
                      </Text>
                    </Box>
                  }
                />
                {/* <InputCustom
                  control={control}
                  name="company"
                  label="Công ty"
                  placeholder="Nhập tên công ty"
                  styleContainer={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}
                  styleBoxInput={{ flex: 1 }}
                /> */}
                <Box mb="64px" marginTop="20px">
                  <RadioGroup onChange={setCheckPolicy} value={checkPolicy}>
                    <Radio textColor="#666666" fontSize="16px" colorScheme="green" fontWeight={500} textAlign={'start'} value="1">
                      Tôi đồng ý với các điều khoản trong
                      <Link href="#" target="_blank" color="#1DCA74">
                        &nbsp;Thoả thuận sử dụng
                      </Link>
                      &nbsp;và&nbsp;
                      <Link href="#" target="_blank" color="#1DCA74">
                        Chính sách bảo vệ dữ liệu cá nhân.
                      </Link>
                    </Radio>
                  </RadioGroup>
                  {errorPolicy && <FormErrorMessage>{errorPolicy}</FormErrorMessage>}
                </Box>
                {/* <Box marginBottom='16px' textAlign="center">
                <Text textColor='#FF7D7D' fontStyle='italic' fontSize='14px'>Tên đăng nhập hoặc mật khẩu không đúng.</Text>
              </Box> */}
                <Box textAlign="center">
                  <Button
                    colorScheme="#1DCA74"
                    bgColor="#1DCA74"
                    textColor="#FFFFFF"
                    w="100%"
                    paddingY={7}
                    fontSize="16px"
                    fontWeight="700"
                    type="submit"
                  >
                    Đăng ký
                  </Button>
                </Box>
              </form>

              <Box paddingY="16px" fontSize="16px" textAlign="center">
                Bạn đã có tài khoản?&nbsp;
                <LinkRoute to="/auth/sign-in" style={{ color: '#1DCA74', fontWeight: '600' }}>
                  Đăng nhập
                </LinkRoute>
              </Box>
            </Box>
          </GridItem>
          <GridItem
            w="100%"
            display={{ base: 'none', lg: 'flex' }}
            colSpan={{ base: '12', md: '7' }}
            paddingX="auto"
            justifyContent="center"
            // alignItems="center"
          >
            <Box>
              <Image src={bg_register} alt="bg_register" />
            </Box>
          </GridItem>
        </Grid>
      </div>
    </Box>
  );
}

export default SignUp;
