import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Grid, GridItem, Heading, Image, Link, Text } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useHistory, Link as LinkRoute, useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import { useUserDispatch, loginUser } from 'context/UserContext';
import { CookieStorage } from 'utils/cookie-storage';
import InputCustom from '../../components/Form/InputCustom';
import { LoginFormValidate } from '../../utils/validation';
import { useLoginMutation } from '../../services/user';
import { ADVERTISER_PAGE_URL } from 'constants/common';
import bg_login from '../../assets/img/bg_login_ad.png';
import { FiEye } from 'react-icons/fi';
import { FiEyeOff } from 'react-icons/fi';
import { toast } from 'components/Toast';
import queryString from 'query-string';

function SignIn() {
  const userDispatch = useUserDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const params = queryString.parse(search);
  const loginMutation = useLoginMutation();
  const isLoggedIn = CookieStorage.isAuthenticated();
  const [show, setShow] = useState(false);
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(LoginFormValidate),
    defaultValues: {
      username: '',
      password: '',
    },
  });

  useEffect(() => {
    if (isLoggedIn) {
      return history.push('/admin');
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (params?.verifyAccount === 'success') {
      toast.showMessageSuccess('Xác thực tài khoản thành công');
    }
  }, [params?.verifyAccount]);

  const onSubmit = values => {
    loginMutation.mutate(
      { ...values },
      {
        onSuccess: response => {
          const { data, token } = response || {};

          loginUser(userDispatch, token, data);
          history.push('/admin/statistics');
        },
      }
    );
  };

  return (
    // <Box minH="100vh">
    //   <div>
    //     <Grid templateColumns="repeat(12, 1fr)" gap={{ base: '0', lg: '40px' }}>
    //       <GridItem w="100%" colSpan={{ base: '12', lg: '6' }} paddingLeft="40px" paddingRight="40px">
    //         <Box marginY="10%" paddingX={{ base: '0', lg: '120px' }}>
    //           <Flex justifyContent="center" alignItems="center" mb="30px">
    //             <Link
    //               href={ADVERTISER_PAGE_URL}
    //               paddingX="12px"
    //               paddingY="6px"
    //               border="1px"
    //               borderColor="#ccc"
    //               bgColor="#fff"
    //               textColor="#333"
    //               borderTopLeftRadius="4px"
    //               borderBottomLeftRadius="4px"
    //             >
    //               Advertiser
    //             </Link>
    //             <Box
    //               paddingX="12px"
    //               paddingY="6px"
    //               border="1px"
    //               borderColor="#2c6ccd"
    //               bgColor="#2c6ccd"
    //               textColor="white"
    //               borderTopRightRadius="4px"
    //               borderBottomRightRadius="4px"
    //             >
    //               Publisher
    //             </Box>
    //           </Flex>
    //           <form>
    //             <InputCustom
    //               control={control}
    //               name="username"
    //               label="Tên đăng nhập"
    //               isRequired
    //               styleContainer={{ marginBottom: '15px' }}
    //               styleBoxInput={{ flex: 1 }}
    //             />
    //             <InputCustom
    //               control={control}
    //               type="password"
    //               name="password"
    //               label="Mật khẩu"
    //               isRequired
    //               styleContainer={{ marginBottom: '15px' }}
    //               styleBoxInput={{ flex: 1 }}
    //             />
    //           </form>
    //           <Flex justifyContent="end">
    //             <Text color="#337ab7" cursor="pointer">
    //               Quên mật khẩu?
    //             </Text>
    //           </Flex>
    //           <Box paddingTop="15px" textAlign="center">
    //             <Button colorScheme="blue" w="100%" onClick={handleSubmit(onSubmit)}>
    //               Đăng nhập
    //             </Button>
    //           </Box>
    //           <Box paddingY="15px" textAlign="center">
    //             Bạn chưa có tài khoản&nbsp;
    //             <LinkRoute to="/auth/sign-up" style={{ color: '#337ab7' }}>
    //               Đăng ký
    //             </LinkRoute>
    //           </Box>
    //         </Box>
    //       </GridItem>
    //       <GridItem
    //         w="100%"
    //         display={{ base: 'none', lg: 'block' }}
    //         colSpan={{ base: '7', md: '6' }}
    //         paddingLeft="40px"
    //         paddingRight="40px"
    //       >
    //         Image
    //       </GridItem>
    //     </Grid>
    //   </div>
    // </Box>
    <Box minH="100vh" maxW="1200px" mx={{ base: '20px', lg: 'auto' }}>
      <div>
        <Grid templateColumns="repeat(12, 1fr)" gap={{ base: '0', lg: '40px' }} px="24px">
          <GridItem w="100%" colSpan={{ base: '12', lg: '5' }}>
            <Box marginY="64px">
              <Flex justifyContent="center" alignItems="center" mb="64px">
                <Link
                  href={ADVERTISER_PAGE_URL}
                  paddingX="24px"
                  paddingY="16px"
                  border="1px"
                  borderColor="#1DCA74"
                  bgColor="#fff"
                  textColor="#1DCA74"
                  fontSize="18px"
                  fontWeight="600"
                  borderTopLeftRadius="10px"
                  borderBottomLeftRadius="10px"
                >
                  Advertiser
                </Link>
                <Box
                  paddingX="24px"
                  paddingY="16px"
                  border="1px"
                  borderColor="#1DCA74"
                  bgColor="#1DCA74"
                  textColor="white"
                  fontSize="18px"
                  fontWeight="600"
                  borderTopRightRadius="10px"
                  borderBottomRightRadius="10px"
                >
                  Publisher
                </Box>
              </Flex>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Heading as="h3" fontSize="24px" mb={18} color="#666666">
                  Đăng nhập Publisher
                </Heading>
                <InputCustom
                  control={control}
                  name="username"
                  label="Tên đăng nhập"
                  placeholder="Tên đăng nhập hoặc email"
                  styleBoxInput={{ flex: 1 }}
                  isRequired
                />
                <Box position="relative">
                  <InputCustom
                    control={control}
                    type={show ? 'text' : 'password'}
                    name="password"
                    label="Mật khẩu"
                    placeholder="Mật khẩu"
                    styleBoxInput={{ flex: 1 }}
                    isRequired
                  />
                  <Box onClick={() => setShow(!show)} position="absolute" zIndex="10" right="4" top="45px" cursor="pointer">
                    {show ? <FiEyeOff size={24} color="#1DCA74" /> : <FiEye size={24} color="#1DCA74" />}
                  </Box>
                </Box>
                <Flex justifyContent="end" marginBottom="64px" mt={'10px'}>
                  <Text textColor="#1DCA74" fontSize={16} fontWeight={500} cursor="pointer">
                    Quên mật khẩu?
                  </Text>
                </Flex>
                {/* <Box marginBottom='16px' textAlign="center">
                <Text textColor='#FF7D7D' fontStyle='italic' fontSize='14px'>Tên đăng nhập hoặc mật khẩu không đúng.</Text>
              </Box> */}
                <Box textAlign="center">
                  <Button
                    colorScheme="#1DCA74"
                    bgColor="#1DCA74"
                    textColor="#FFFFFF"
                    w="100%"
                    paddingY={7}
                    fontSize="16px"
                    fontWeight="700"
                    type="submit"
                  >
                    Đăng nhập
                  </Button>
                </Box>
              </form>

              <Box paddingY="16px" fontSize="16px" textAlign="center">
                Bạn chưa có tài khoản?&nbsp;
                <LinkRoute to="/auth/sign-up" style={{ color: '#1DCA74', fontWeight: '600' }}>
                  Đăng ký
                </LinkRoute>
              </Box>
            </Box>
          </GridItem>
          <GridItem
            w="100%"
            display={{ base: 'none', lg: 'flex' }}
            colSpan={{ base: '12', md: '7' }}
            paddingX="auto"
            justifyContent="center"
            alignItems="center"
          >
            <Box>
              <Image src={bg_login} alt="bg_login" />
            </Box>
          </GridItem>
        </Grid>
      </div>
    </Box>
  );
}

export default SignIn;
