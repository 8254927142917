import { useMutation, useQuery } from '@tanstack/react-query';
import { request } from 'utils/request';

export const getTickets = params => request.get('/api/v1/publisher/ticket', { params });
export const createTicket = data => request.post('/api/v1/publisher/ticket', data);
export const updateTicket = data => request.post(`/api/v1/publisher/ticket/${data.id}`, data);
export const listCategoryTicket = params => request.get('/api/v1/ticket-categories', { params });

// Mutation
export const useCreateTicketMutation = () => useMutation({ mutationFn: createTicket });
export const useUpdateTicketMutation = () => useMutation({ mutationFn: updateTicket });

// Query
export const useQueryGetTickets = (params = {}, options = {}) =>
  useQuery({ queryKey: ['getTickets', params], queryFn: () => getTickets(params), ...options });
export const useQueryGetCategoryTicket = (params = {}, options = {}) => useQuery({ queryKey: ['getListCategoryTicket', params], queryFn: () => listCategoryTicket(params), ...options });
