import { Button, Flex, IconButton, Td, Text, Tooltip, Tr, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { DeleteIcon, EditIcon, AddIcon, InfoIcon } from '@chakra-ui/icons';
import { formatDate } from 'utils/helpers';
import { TYPE_ACTION } from 'constants/common';
import capitalize from 'lodash/capitalize';

function Row({ user, isLast, handelUpdateWebsite, categories }) {
  const textColor = useColorModeValue('gray.500', 'white');
  const titleColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  const category = categoryId => {
    return categories?.find(item => item?._id === categoryId?._id);
  };

  const handleRowClick = () => {
    handelUpdateWebsite(user);
  };

  const handleRowClickDelete = () => {
    handelUpdateWebsite(user, TYPE_ACTION.DELETE);
  };

  const handleAdunitClick = () => {
    handelUpdateWebsite(user, TYPE_ACTION.CREATE);
  };

  const handleDetailClick = () => {
    handelUpdateWebsite(user, TYPE_ACTION.DETAIL);
  };

  const handleDetailWebsite = () => {
    handelUpdateWebsite(user, TYPE_ACTION.SUB_DETAIL);
  };

  return (
    <Tr>
      <Td pl="0px" borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Flex direction="column">
            <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
              {user?.name}
            </Text>
          </Flex>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null} maxWidth="300px">
        <Flex direction="column">
          <Tooltip label={user?.url} placement="top">
            <Text fontSize="md" color={textColor} fontWeight="bold" noOfLines={1}>
              {user?.url}
            </Text>
          </Tooltip>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {capitalize(user?.status)}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {formatDate(user?.createdAt)}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {formatDate(user?.updatedAt)}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {category(user?.categoryId)?.name}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null} minW={300}>
        <Flex>
          {/* <Button
            _hover={{ background: 'gray.400', color: 'white' }}
            color={textColor}
            display={'flex'}
            alignItems={'center'}
            gap={'6px'}
            bg="transparent"
            onClick={() => handleAdunitClick()}
          >
            <AddIcon />
            <span>Thêm đơn vị quảng cáo</span>
          </Button> */}
          <Button
            _hover={{ background: 'gray.400', color: 'white' }}
            color={textColor}
            display={'flex'}
            alignItems={'center'}
            gap={'6px'}
            bg="transparent"
            onClick={() => handleRowClick()}
          >
            <InfoIcon />
            <span>Chi tiết</span>
          </Button>
          {/* <IconButton
            p={2}
            bg="transparent"
            onClick={() => {
              handleDetailWebsite();
            }}
          >
            <BiMessageSquareDetail />
          </IconButton> */}
          {/* <IconButton
            p={2}
            bg="transparent"
            onClick={() => {
              handleRowClick();
            }}
          >
            <EditIcon />
          </IconButton> */}
          <IconButton
            p={2}
            bg="transparent"
            onClick={() => {
              handleRowClickDelete();
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Flex>
      </Td>
    </Tr>
  );
}

export default Row;
