import { Flex, IconButton, Stack, Td, Text, Tr, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { EditIcon } from '@chakra-ui/icons';
import { IoCodeSlash } from 'react-icons/io5';
import { formatDate } from 'utils/helpers';
import { useGetCodeDirectLinkMutation } from 'services/website';
import useCopyToClipboard from 'hooks/use-copy-to-clipboard';
import { toast } from 'components/Toast';

function Row({ directLinkDetail, isLast, handelUpdateDirectLink }) {
  const textColor = useColorModeValue('gray.500', 'white');
  const titleColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const [, copy] = useCopyToClipboard();
  const getCodeDirectLinkMutation = useGetCodeDirectLinkMutation();

  const handleRowClick = () => {
    handelUpdateDirectLink(directLinkDetail);
  };

  const getCodeDirectLink = id => {
    getCodeDirectLinkMutation.mutate(
      { id },
      {
        onSuccess: res => {
          copy(res.data);
          toast.showMessageSuccess(
            <Flex flexDirection="column">
              <span>Sao chép đường dẫn thành công.</span>
              <span>Đường dẫn: {res.data}</span>
            </Flex>
          );
        },
        onError: () => {},
      }
    );
  };

  return (
    <Tr>
      <Td pl="0px" borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Flex direction="column">
            <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
              {directLinkDetail?.name} - {directLinkDetail?.createdBy?._id?.slice(-5)}
            </Text>
          </Flex>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {directLinkDetail?.category?.name}
          </Text>
        </Flex>
      </Td>
      {/* <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {directLinkDetail?.removeCampaignWith.map((item, index) => (
              <span key={index}>
                {item}
                {directLinkDetail?.removeCampaignWith.length === index + 1 ? '' : ', '}
              </span>
            ))}
          </Text>
        </Flex>
      </Td> */}
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {directLinkDetail?.status ? 'Đã kích hoạt' : 'Chưa kích hoạt'}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {formatDate(directLinkDetail?.createdAt)}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <IconButton
          p={2}
          bg="transparent"
          isDisabled={!directLinkDetail?.status}
          onClick={() => {
            getCodeDirectLink(directLinkDetail?._id);
          }}
        >
          <IoCodeSlash />
        </IconButton>
        <IconButton
          p={2}
          bg="transparent"
          onClick={() => {
            handleRowClick();
          }}
        >
          <EditIcon />
        </IconButton>
      </Td>
    </Tr>
  );
}

export default Row;
