import React, { useState } from 'react';
import { GiMoneyStack } from 'react-icons/gi';
import { Box, Button, Flex, Image, Input, InputGroup, InputLeftElement, Text } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { useUserState } from 'context/UserContext';
import { formatDate } from 'utils/helpers';
import { ErrorForm } from 'constants/error';
import { WithDrawFormValidate } from 'utils/validation';
import { useCreateTransactionMutation, useQueryGetBanks, useWithdrawMutation } from 'services/transaction';
import { toast } from 'components/Toast';
import { Bank, BankMethod, BankStatus, MethodsText, PaymentGateway } from 'constants/bank';
import SelectController from 'components/Form/SelectController';
import InputController from 'components/Form/InputController';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TabFeatureProfile } from 'constants/common';

function Withdraw({ setActiveTabId, refetch }) {
  const { userInfo } = useUserState();
  const withdrawMutation = useWithdrawMutation();
  const { control, handleSubmit, reset, setValue } = useForm({
    resolver: yupResolver(WithDrawFormValidate),
    defaultValues: {
      amount: '',
      bank_code: undefined,
      bank_number: '',
      beneficiary_name: '',
    },
  });
  const handleSuccess = () => {
    toast({
      title: 'Yêu cầu rút tiền đã được gửi.',
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
  };
  const onSubmit = values => {
    withdrawMutation.mutate(
      { ...values, bank_code: values?.bank_code?.value },
      {
        onSuccess: () => {
          setActiveTabId(TabFeatureProfile.HistoryWithdraw);
          refetch();
          reset();
          handleSuccess();
        },
      }
    );
  };

  return (
    <>
      <Flex bg={'#E2E2E2'} rounded={'20px'} padding={'20px'} gap={'20px'}>
        <Image rounded={'20px'} src="gibbresh.png" fallbackSrc="https://via.placeholder.com/150" />
        <Flex direction={'column'} gap={'16px'}>
          <Flex color={'#464646'} fontSize={'18px'}>
            Ngày tham gia:&nbsp;
            <Text display={'inline'} fontWeight={'600'}>
              {formatDate(userInfo.createdAt)}
            </Text>
          </Flex>
          <Flex color={'#464646'} fontSize={'18px'}>
            Mã giới thiệu:&nbsp;
            <Text display={'inline'} fontWeight={'600'}>
              {userInfo.referralCode}
            </Text>
          </Flex>
          <Flex color={'#464646'} fontSize={'18px'}>
            Số dư trên ví:&nbsp;
            <Text display={'inline'} fontWeight={'600'}>
              {userInfo.balance}₫
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex direction={'column'} mt={10}>
        <Text fontWeight={'600'} fontSize={'20px'} mb={'20px'}>
          Thông tin tài khoản
        </Text>
        <form>
          <InputController styleContainer={{ pt: '4' }} control={control} name="amount" label="Số tiền" isRequired />
          <SelectController
            styleContainer={{ pt: '4' }}
            focusBorderColor={'#3366FF'}
            control={control}
            name="bank_code"
            label="Tên ngân hàng"
            placeholder="Chọn"
            isRequired
            options={Bank}
          />
          <InputController styleContainer={{ pt: '4' }} control={control} name="bank_number" label="Số TK" isRequired />
          <InputController styleContainer={{ pt: '4' }} control={control} name="beneficiary_name" label="Chủ sở hữu" isRequired />
          <Button
            colorScheme="blue"
            mt={4}
            //   isDisabled={isEmpty(editTicketDetail) ? false : editTicketDetail?.status !== STATUS_TICKET.PENDING}
            onClick={handleSubmit(onSubmit)}
          >
            Gửi yêu cầu
          </Button>
        </form>
      </Flex>
    </>
  );
}

export default Withdraw;
