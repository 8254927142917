import React, { useState } from 'react';
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Stack,
  Switch,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import isEmpty from 'lodash/isEmpty';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import Pagination from 'components/Pagination/Pagination';
import Row from './components/Row';
import { StatusTicketOptions, TYPE_ACTION, TypeTicket } from 'constants/common';
import TicketForm from './components/TicketForm';
import { useQueryGetCategoryTicket, useQueryGetTickets } from 'services/support';
import InputSearch from 'components/InputSearch/InputSearch';
import DatePicker from 'components/DatePicker/DatePicker';
import MultiSelectMenu from 'components/MultiSelect';
import moment from 'moment';
import { mappingOptionSelect } from 'utils/mapping';

export const initialFilter = {
  pageSize: 10,
  pageIndex: 0,
  startDate: null,
  endDate: null
};

function Support() {
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const [editTicket, seteditTicket] = useState(null);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  // const [ticketType, setTicketType] = useState(null);
  const [ticketStatus, setTicketStatus] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState('');
  // const [filterDate, setFilterDate] = useState({ startDate: null, endDate: null });
  //   const [switched, setSwitched] = useState(false);
  const [filterTickets, setFilterTickets] = useState([]);
  const [filterDate, setFilterDate] = useState({ startDate: null, endDate: null });
  const [isOpenMultiSelect, setIsOpenMultiSelect] = useState(false);
  const { data: categories } = useQueryGetCategoryTicket();

  const { isOpen: isRegisterOpen, onOpen: onRegisterOpen, onClose: onRegisterClose } = useDisclosure();

  const {
    data,
    // isLoading: isLoadingComics,
    refetch,
  } = useQueryGetTickets({
    ...filter,
  });

  const onReset = () => {
    setFilter(initialFilter);
    setSearchKeyword('');
    setTicketStatus(null);
    setFilterDate({ startDate: null, endDate: null });
    // setTicketType(null);
    setFilterTickets([])
  };

  const onFilter = () => {
    setFilter({
      ...filter,
      ...(filterDate.startDate && { startDate: moment(filterDate.startDate).startOf('day').toString() }),
      ...(filterDate.endDate && { endDate: moment(filterDate.endDate).endOf('day').toString() }),
      type: filterTickets.map(item => item.value),
      status: ticketStatus?.value,
      searchKeyword: searchKeyword,
    });
  };

  const handelUpdateTicket = (editTicket, type = TYPE_ACTION.UPDATE) => {
    if (type === TYPE_ACTION.UPDATE) {
      onRegisterOpen();
      seteditTicket(editTicket);
    }
  };

  const handelCloseModal = () => {
    seteditTicket(null);
    onRegisterClose();
  };

  const onClearSearch = () => {
    setFilter(initialFilter);
    setSearchKeyword('');
  };

  const onChangeSearch = event => {
    event.persist();
    setSearchKeyword(event.target.value);
  };

  const onChangeDate = type => date => {
    setFilterDate(prev => ({
      ...prev,
      ...(type === 'startDate' && { endDate: null }),
      [type]: date,
    }));
  };

  const handleBlurTicket = values => {
    setFilterTickets(values)
  }

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
      <Card p="16px" mb="24px" bg="#fff">
        <CardHeader p="12px 5px" mb="12px">
          <Flex justifyContent={'space-between'}>
            <Flex direction={'column'}>
              {/* <Flex direction="column" gap={'20px'}>
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Ticket
                </Text>
              </Flex> */}
              <Flex justifyContent={'space-between'} alignItems={'end'} gap={'20px'}>
                <Stack>
                  <Flex alignItems={'end'} gap={'20px'} flexWrap={{ base: 'wrap', md: 'nowrap' }}>
                    <FormControl minWidth={{ base: '200px', sm: '300px' }} maxW="300px">
                      <FormLabel>Tìm kiếm theo tiêu đề</FormLabel>
                      <InputSearch value={searchKeyword} onChange={onChangeSearch} onClearSearch={onClearSearch} />
                    </FormControl>
                    {/* <FormControl minWidth={{ base: 'full', sm: '300px' }}>
                      <FormLabel>Loại Ticket</FormLabel>
                      <Select
                        isClearable
                        menuShouldBlockScroll
                        value={ticketType}
                        placeholder="Chọn"
                        onChange={e => {
                          setTicketType(e);
                        }}
                        options={TypeTicket}
                      ></Select>
                    </FormControl> */}
                    <FormControl minWidth={{ base: '200px', sm: '300px' }} maxW="300px">
                      <FormLabel>Loại Ticket</FormLabel>
                      <MultiSelectMenu
                        label="Chọn ticket"
                        options={mappingOptionSelect(categories?.data) || []}
                        onBlur={handleBlurTicket}
                        minWidth={{ base: '200px', sm: '300px' }}
                        onClose={() => setIsOpenMultiSelect(false)}
                        onOpen={() => setIsOpenMultiSelect(true)}
                        isOpen={isOpenMultiSelect}
                        filterTickets={filterTickets}
                      />
                    </FormControl>
                    <FormControl minWidth={{ base: '200px', sm: '300px' }} maxW="300px">
                      <FormLabel>Trạng thái</FormLabel>
                      <Select
                        isClearable
                        menuShouldBlockScroll
                        value={ticketStatus}
                        placeholder="Chọn"
                        onChange={e => {
                          setTicketStatus(e);
                        }}
                        options={StatusTicketOptions}
                      ></Select>
                    </FormControl>
                  </Flex>
                  <Flex flexWrap="wrap" marginTop={4} gap={'20px'}>
                    <FormControl display="flex" flexDirection={'column'} width={{ base: 'full', sm: '300px' }} maxW="300px">
                      <FormLabel m="0">Từ ngày</FormLabel>
                      <DatePicker selectedDate={filterDate.startDate} onChange={date => onChangeDate('startDate')(date)} />
                    </FormControl>
                    <FormControl display="flex" flexDirection={'column'} width={{ base: 'full', sm: '300px' }} maxW="300px">
                      <FormLabel m="0">Đến ngày</FormLabel>
                      <DatePicker
                        selectedDate={filterDate.endDate}
                        minDate={filterDate.startDate}
                        onChange={date => onChangeDate('endDate')(date)}
                      />
                    </FormControl>
                    <Button
                      backgroundColor="white"
                      color="blue.400"
                      border="1px"
                      _hover={{
                        text: 'white',
                      }}
                      maxH="30px"
                      mx="0px"
                      fontSize="14px"
                      alignSelf={'end'}
                      px={8}
                      onClick={onReset}
                    >
                      Đặt lại
                    </Button>
                    <Button variant="primary" minW={50} maxW={150} maxH="30px" fontSize="14px" onClick={onFilter} alignSelf={'end'}>
                      Lọc
                    </Button>
                  </Flex>
                </Stack>
              </Flex>
            </Flex>
            <Button bg="#3182ce" color="#fff" _hover={{ bg: '#67a1d7' }} onClick={onRegisterOpen}>
              <Text fontSize="md" fontWeight="bold" cursor="pointer">
                Thêm
              </Text>
            </Button>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto">
          <Table variant="simple" color={textColor} overflowX="auto">
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
              <Th borderColor={borderColor} color="gray.400">
                  Loại ticket
                </Th>
                <Th borderColor={borderColor} color="gray.400">
                  Tiêu đề
                </Th>
                <Th borderColor={borderColor} color="gray.400">
                  Nội dung
                </Th>
                <Th borderColor={borderColor} color="gray.400">
                  Trạng thái
                </Th>
                <Th pl="0px" borderColor={borderColor} color="gray.400">
                  Ngày tạo
                </Th>
                <Th borderColor={borderColor} color="gray.400">
                  Ngày cập nhật
                </Th>
                <Th borderColor={borderColor} color="gray.400">
                  Thao tác
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {isEmpty(data?.data) ? (
                <Tr>
                  <Td textAlign="center" colSpan={7}>
                    Không có dữ liệu
                  </Td>
                </Tr>
              ) : (
                data?.data?.map((row, index, arr) => {
                  return (
                    <Row
                      key={row._id}
                      user={row}
                      isLast={index === arr.length - 1 ? true : false}
                      refetch={refetch}
                      handelUpdateTicket={handelUpdateTicket}
                    />
                  );
                })
              )}
            </Tbody>
          </Table>
          <Flex justifyContent={'flex-end'}>
            <Pagination
              page={data?.pagination?.page}
              pageLength={data?.pagination?.pageSize}
              totalRecords={data?.pagination?.count}
              onPageChange={(page, pageLength) => {
                setFilter({
                  ...filter,
                  pageSize: pageLength,
                  pageIndex: page - 1,
                });
              }}
            />
          </Flex>
        </CardBody>
      </Card>
      {isRegisterOpen && <TicketForm editTicketDetail={editTicket} category={mappingOptionSelect(categories?.data)} refetch={refetch} isOpen={isRegisterOpen} onClose={handelCloseModal} />}
    </Flex>
  );
}

export default Support;
