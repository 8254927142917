import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Image,
  Text,
  Wrap,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import VTlist from './VerticalList';
import VTcontent from './VerticalContent';
import { useUserState } from 'context/UserContext';
import InputController from 'components/Form/InputController';
import { CountryOptions, ROOT_API, TabFeatureProfile } from 'constants/common';
import { useUpdateProfileMutation } from 'services/user';
import { toast } from 'components/Toast';
import { CookieStorage } from 'utils/cookie-storage';
import { StorageKeys } from 'constants/storage-keys';
import ChangePassword from './ChangePassword';
import { UpdateProfileFormValidate } from 'utils/validation';
import Rechange from 'views/Dashboard/components/Rechange';
import { ErrorForm } from 'constants/error';
import SelectController from 'components/Form/SelectController';
import Withdraw from 'views/Dashboard/components/Withdraw';
import HistoryWithdraw from 'views/Dashboard/components/HistoryWithdraw';
import { useQueryListHistoryWithdraw } from 'services/transaction';
export const initialFilter = {
  pageSize: 10,
  pageIndex: 0,
};
function VerticalTab(props) {
  const [activeTabId, setActiveTabId] = useState(TabFeatureProfile.IdentityInfo);
  const [imageFrontSide, setImageFrontSide] = useState(null);
  const [imageBackSide, setImageBackSide] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);

  const { userInfo } = useUserState();
  const updateProfile = useUpdateProfileMutation();
  const { colorMode } = useColorMode();
  const textColor = useColorModeValue('white', 'white');
  const textTitleColor = useColorModeValue('gray.600', 'white');

  function handleClickButton(id) {
    setActiveTabId(id);
  }
  const [filter, setFilter] = useState(initialFilter);
  const { data, isLoading, refetch } = useQueryListHistoryWithdraw({...filter});
  const { control, register, handleSubmit, setValue, setError } = useForm({
    resolver: yupResolver(UpdateProfileFormValidate),
  });
  const { ...rest } = register;

  // const handleValidateImage = () => {
  //   if (isEmpty(imageFrontSide)) {
  //     setError('frontSideFile', { type: 'string', message: ErrorForm.Required });
  //   }
  //   if (isEmpty(imageBackSide)) {
  //     setError('backSideFile', { type: 'string', message: ErrorForm.Required });
  //   }

  //   return isEmpty(imageBackSide) || isEmpty(imageFrontSide);
  // };

  const onSubmit = values => {
    if (!isEmpty(values)) {
      updateProfile.mutate(
        {
          email: values?.email,
          phone: values?.phone,
          fullname: values?.fullname,
          // codeNumber: values?.codeNumber,
          // ...(!isUpdate && { frontSideFile: imageFrontSide[0], backSideFile: imageBackSide[0] }),
        },
        {
          onSuccess: res => {
            res && CookieStorage.setCookieData(StorageKeys.UserInfo, JSON.stringify(res?.data));
            toast.showMessageSuccess('Cập nhật thông tin thành công');
            setIsUpdate(true);
            setValue('fullname', res?.data?.fullname);
            // setValue('codeNumber', res?.data?.codeNumber);
            setValue('email', res?.data?.email);
            setValue('phone', res?.data?.phone);
            // setImageBackSide(res?.data?.backIdentificationCard);
            // setImageFrontSide(res?.data?.frontIdentificationCard);
          },
        }
      );
    }
  };

  useEffect(() => {
    setValue('username', userInfo?.username);
    setValue('fullname', userInfo?.fullname);
    setValue('email', userInfo?.email);
    setValue('phone', userInfo?.phone);
    // setValue('codeNumber', userInfo?.codeNumber);
    // if (!isEmpty(userInfo?.backIdentificationCard) && !isEmpty(userInfo?.frontIdentificationCard)) {
    //   userInfo?.backIdentificationCard && setImageBackSide(userInfo?.backIdentificationCard);
    //   userInfo?.frontIdentificationCard && setImageFrontSide(userInfo?.frontIdentificationCard);
    //   setIsUpdate(true);
    // }
  }, [userInfo]);

  return (
    <Wrap w={'100%'}>
      <Grid w={'100%'} gridTemplateColumns={{ base: '1fr', md: '20% 1fr' }} gap={6}>
        <GridItem w="100%">
          <Flex bg={'white'}  w="100%" borderRadius={4} padding={'20px'}>
            <Flex  w="100%" flexDirection={'column'} gap={'10px'}>
              {props.data.map((item, index) => (
                <VTlist key={index} onClick={handleClickButton} data={item} index={index} activeTabId={activeTabId} />
              ))}
            </Flex>
          </Flex>
        </GridItem>
        <GridItem w="100%">
        <VTcontent
            data={{
              expData: {
                company: 'Identity Info',
                position: 'Graduate Student',
                period: 'Oct 2018 - present',
                details: [
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc dignissim fringilla dui ac mattis.',
                  'Donec in sodales eros. Nulla fermentum, ante in venenatis pellentesque, justo odio viverra lorem, varius posuere erat tortor et magna.',
                ],
              },
            }}
            key={TabFeatureProfile.IdentityInfo}
            index={TabFeatureProfile.IdentityInfo}
            activeTabId={activeTabId}
          >
            <Card w={'100%'} py="10px">
              <CardHeader p="6px 20px 10px 20px">
                <Flex direction="column">
                  <Text fontSize="larger" color={textTitleColor} fontWeight="bold">
                    Thông tin cá nhân
                  </Text>
                </Flex>
              </CardHeader>
              <Divider color={textColor} />
              <CardBody>
                <form>
                  <InputController
                    control={control}
                    name="username"
                    label="Username"
                    isRequired
                    styleContainer={{ display: 'flex', alignItems: 'start', marginBottom: '15px' }}
                    styleBoxInput={{ flex: 1 }}
                    styleLabel={{ marginTop: '10px' }}
                    disabled
                  />
                  <InputController
                    control={control}
                    name="email"
                    label="Email"
                    isRequired
                    styleContainer={{ display: 'flex', alignItems: 'start', marginBottom: '15px' }}
                    styleBoxInput={{ flex: 1 }}
                    styleLabel={{ marginTop: '10px' }}
                    disabled
                  />
                  <InputController
                    control={control}
                    name="fullname"
                    label="Tên đầy đủ"
                    isRequired
                    styleContainer={{ display: 'flex', alignItems: 'start', marginBottom: '15px' }}
                    styleBoxInput={{ flex: 1 }}
                    styleLabel={{ marginTop: '10px' }}
                  />
                  {/* <SelectController
                    control={control}
                    placeholder="Chọn quốc gia"
                    name="countryCode"
                    label="Quốc gia"
                    options={CountryOptions}
                    styleContainer={{ display: 'flex', alignItems: 'start', marginBottom: '15px' }}
                    styleBoxInput={{ flex: 1 }}
                    focusBorderColor="#3182ce"
                    size="md"
                  /> */}
                  <InputController
                    control={control}
                    name="phone"
                    label="Số điện thoại"
                    isRequired
                    styleContainer={{ display: 'flex', alignItems: 'start', marginBottom: '15px' }}
                    styleBoxInput={{ flex: 1 }}
                    styleLabel={{ marginTop: '10px' }}
                  />
                  {/* <InputController
                    control={control}
                    type="text"
                    name="codeNumber"
                    label="Số CMT/CCCD"
                    isRequired
                    styleContainer={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}
                    styleBoxInput={{ flex: 1 }}
                  /> */}
                  {/* <Flex flexDirection={'column'}>
                    <FormControl isRequired>
                      <FormLabel>Ảnh CMT/CCCD</FormLabel>
                    </FormControl>
                    <Flex flexDirection={{ base: 'column', md: 'row' }}>
                      <InputController
                        control={control}
                        name="frontSideFile"
                        label={
                          <Flex alignItems={'center'} gap={'10px'}>
                            <Text fontWeight={'600'}>Mặt trước</Text>
                            {!isUpdate && (
                              <Text cursor={'pointer'} border={'1px solid #ccc'} textAlign={'center'} padding={'4px'} borderRadius={'10px'}>
                                Tải lên
                              </Text>
                            )}
                          </Flex>
                        }
                        requiredIndicator=""
                        isRequired
                        styleContainer={{ display: 'flex', flexDirection: 'column', alignItems: 'start', marginBottom: '15px' }}
                        styleBoxInput={{ flex: 1 }}
                        type={'file'}
                        multiple={true}
                        hidden
                        onChange={e => setImageFrontSide(e.target.files)}
                        accept={'image/*'}
                        {...rest}
                      />
                      <InputController
                        control={control}
                        name="backSideFile"
                        isRequired
                        requiredIndicator=""
                        styleContainer={{ display: 'flex', flexDirection: 'column', alignItems: 'start', marginBottom: '15px' }}
                        styleBoxInput={{ flex: 1 }}
                        type={'file'}
                        multiple={true}
                        hidden
                        onChange={e => setImageBackSide(e.target.files)}
                        accept={'image/*'}
                        {...rest}
                        label={
                          <Flex alignItems={'center'} gap={'10px'}>
                            <Text fontWeight={'600'}>Mặt sau</Text>
                            {!isUpdate && (
                              <Text cursor={'pointer'} border={'1px solid #ccc'} textAlign={'center'} padding={'4px'} borderRadius={'10px'}>
                                Tải lên
                              </Text>
                            )}
                          </Flex>
                        }
                      />
                    </Flex>

                    <Flex flexDirection={{ base: 'column', md: 'row' }} gap={'30px'}>
                      {!isEmpty(imageFrontSide) && (
                        <Image
                          order={0.5}
                          w={{ md: '50%', base: '100%' }}
                          objectFit={'cover'}
                          height={'200px'}
                          src={
                            typeof imageFrontSide === 'string' ? `${ROOT_API}/${imageFrontSide}` : URL.createObjectURL(imageFrontSide[0])
                          }
                          alt="front side"
                        />
                      )}
                      {!isEmpty(imageBackSide) && (
                        <Image
                          order={0.5}
                          w={{ md: '50%', base: '100%' }}
                          objectFit={'cover'}
                          height={'200px'}
                          src={typeof imageBackSide === 'string' ? `${ROOT_API}/${imageBackSide}` : URL.createObjectURL(imageBackSide[0])}
                          alt="back side"
                        />
                      )}
                    </Flex>
                  </Flex> */}
                </form>

                <Button mt={'30px'} p="0px" bg="transparent" variant="no-effects">
                  <Flex
                    align="center"
                    w={{ sm: '100%', lg: '135px' }}
                    bg={colorMode === 'dark' ? 'navy.900' : 'blue.500'}
                    borderRadius="8px"
                    justifyContent="center"
                    py="10px"
                    boxShadow="2px 2px 5.5px rgba(0, 0, 0, 0.06)"
                    cursor="pointer"
                    onClick={handleSubmit(onSubmit)}
                  >
                    <Text fontSize="small" color={'white'} fontWeight="bold">
                      Cập nhật thông tin
                    </Text>
                  </Flex>
                </Button>
              </CardBody>
            </Card>
          </VTcontent>
          <VTcontent
            data={{
              expData: {
                company: 'Change Password',
                position: 'Research Assistant',
                period: 'Oct 2016 - May 2018',
                details: [
                  'Suspendisse potenti. Vestibulum aliquam luctus sem, at feugiat felis. Pellentesque dignissim lorem eu ipsum condimentum varius. ',
                  'Nam vehicula pretium arcu. Nam venenatis ante et porta pellentesque.',
                ],
              },
            }}
            key={TabFeatureProfile.ChangePassword}
            index={TabFeatureProfile.ChangePassword}
            activeTabId={activeTabId}
          >
            <Card w={'100%'} py="10px">
              <CardHeader p="6px 20px 10px 20px">
                <Flex direction="column">
                  <Text fontSize="larger" color={textTitleColor} fontWeight="bold">
                    Đổi mật khẩu
                  </Text>
                </Flex>
              </CardHeader>
              <Divider color={textColor} />
              <CardBody>
                <ChangePassword />
              </CardBody>
            </Card>
          </VTcontent>
          <VTcontent
            key={TabFeatureProfile.Withdraw}
            index={TabFeatureProfile.Withdraw}
            activeTabId={activeTabId}
          >
            <Card w={"100%"} py="10px">
              <CardHeader p="6px 20px 10px 20px">
                <Flex direction="column">
                  <Text fontSize="larger" color={textTitleColor} fontWeight="bold">
                    Yêu cầu rút tiền
                  </Text>
                </Flex>
              </CardHeader>
              <Divider color={textColor} />
              <CardBody>
                <Withdraw setActiveTabId={setActiveTabId} refetch={refetch}/>
              </CardBody>
            </Card>
          </VTcontent>
          <VTcontent
            key={TabFeatureProfile.HistoryWithdraw}
            index={TabFeatureProfile.HistoryWithdraw}
            activeTabId={activeTabId}
          >
            <Card w={"100%"} py="10px">
              <CardHeader p="6px 20px 10px 20px">
                <Flex direction="column">
                  <Text fontSize="larger" color={textTitleColor} fontWeight="bold">
                    Lịch sử rút tiền
                  </Text>
                </Flex>
              </CardHeader>
              <Divider color={textColor} />
              <CardBody>
                <HistoryWithdraw data={data} isLoading={isLoading}/>
              </CardBody>
            </Card>
          </VTcontent>
          {/* <VTcontent
            key={TabFeatureProfile.Rechange}
            index={TabFeatureProfile.Rechange}
            activeTabId={activeTabId}
          >
            <Card w={"100%"} py="10px">
              <CardHeader p="6px 20px 10px 20px">
                <Flex direction="column">
                  <Text fontSize="larger" color={textTitleColor} fontWeight="bold">
                    Nạp tiền
                  </Text>
                </Flex>
              </CardHeader>
              <Divider color={textColor} />
              <CardBody>
                <Rechange />
              </CardBody>
            </Card>
          </VTcontent> */}
          
        </GridItem>
      </Grid>
    </Wrap>
  );
}

export default VerticalTab;
