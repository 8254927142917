import React from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';

import InputController from 'components/Form/InputController';
import SelectController from 'components/Form/SelectController';
import { STATUS_TICKET } from 'constants/common';
import { useCreateTicketMutation, useUpdateTicketMutation } from 'services/support';
import { TicketFormValidate } from 'utils/validation';

const TicketForm = ({ isOpen, category, editTicketDetail, onClose, refetch }) => {
  const cancelRef = React.useRef();
  const toast = useToast();
  const createTicket = useCreateTicketMutation();
  const updateTicket = useUpdateTicketMutation();

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(TicketFormValidate),
    defaultValues: {
      subject: editTicketDetail?.subject || '',
      content: editTicketDetail?.content || '',
      type: category?.find(item => item.value === editTicketDetail?.type?._id) || undefined,
    },
  });

  const handleSuccess = () => {
    toast({
      title: `${!isEmpty(editTicketDetail) ? 'Cập nhật' : 'Đã tạo'} thành công.`,
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    refetch();
    onClose();
  };

  const onSubmit = values => {
    if (!isEmpty(editTicketDetail)) {
      updateTicket.mutate(
        { ...values, type: values?.type?.value, id: editTicketDetail?._id },
        {
          onSuccess: () => {
            handleSuccess();
          },
        }
      );

      return;
    }

    createTicket.mutate(
      { ...values, type: values?.type?.value },
      {
        onSuccess: () => {
          handleSuccess();
        },
      }
    );
  }; 

  return (
    <>
      <AlertDialog motionPreset="slideInBottom" leastDestructiveRef={cancelRef} onClose={onClose} isOpen={isOpen} isCentered>
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader textTransform="uppercase">
            {isEmpty(editTicketDetail) ? 'Tạo thông tin Ticket' : 'Thông tin Ticket'}
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <form>
              <SelectController control={control} name="type" label="Loại" placeholder="Chọn" isRequired options={category} />
              <InputController styleContainer={{ pt: '4' }} control={control} name="subject" label="Tiêu đề" isRequired />
              <InputController
                type="textarea"
                styleContainer={{ pt: '4' }}
                control={control}
                name="content"
                label="Nội dung"
                isRequired
                rows={6}
              />
            </form>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Hủy
            </Button>

            <Button
              colorScheme="blue"
              ml={3}
              isDisabled={isEmpty(editTicketDetail) ? false : editTicketDetail?.status !== STATUS_TICKET.PENDING}
              onClick={handleSubmit(onSubmit)}
            >
              {isEmpty(editTicketDetail) ? 'Tạo' : 'Cập nhật'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default TicketForm;
