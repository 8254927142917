import LogoACB from 'assets/img/method-payment/ACB.png'
import LogoSAC from 'assets/img/method-payment/SAC.png'
import LogoBIDV from 'assets/img/method-payment/BIDV.png'
import LogoMSB from 'assets/img/method-payment/MSB.png'
import LogoVCB from 'assets/img/method-payment/VCB.png'
import LogoTCB from 'assets/img/method-payment/TCB.png'
import LogoMB from 'assets/img/method-payment/MB.png'
import LogoVP from 'assets/img/method-payment/VP.png'

export const BankStatus = {
    Available: '1',
    InMaintenance: '2',
};

export const PaymentGateway = {
    MomoQrCode: 'momo_qr',
    ZaloQrCode: 'zalo_qr',
    LoadCard: 'rcgcard_pc',
    OnlineBanking: 'upacp_pc',
    ViettelpayQr: 'viettel_qr',
    BankTransfer: 'bank_transfer',
    BankTransferQr: 'bank_qr',
    LoadCardZing: 'rcgcard_zing',
    VipQr: 'vip_qr',
    Bank: 'bank',
    UsdtQr: 'usdt_qr',
};

export const BankLogo = {
    ACB: LogoACB,
    SAC: LogoSAC,
    BIDV: LogoBIDV,
    MSB: LogoMSB,
    VCB: LogoVCB,
    TCB: LogoTCB,
    MB: LogoMB,
    VP: LogoVP,
  };

export const MethodsText = {
    momo_qr: 'Momo',
    zalo_qr: 'Zalo',
    rcgcard_pc: 'Thẻ cào',
    upacp_pc: 'Internet Banking',
    viettel_qr: 'Viettel Pay',
    bank_transfer: 'Chuyển nhanh 24/7',
    bank_qr: 'Quét mã ngân hàng',
    rcgcard_zing: 'Thẻ cào Zing',
    vip_qr: 'VIP QR',
    usdt_qr: 'USDT',
    bank: 'Ngân hàng',
};

export const Bank = [
    {value:'TCB', label:'TECHCOMBANK (TCB)'},
    {value:'DBS', label:'DBS - CHI NHANH THANH PHO HO CHI MINH'},
    {value:'IBK', label:'NGAN HANG CONG NGHIEP HAN QUOC'},
    {value:'IBKHCM', label:'NGAN HANG CONG NGHIEP HAN QUOC CHI NHANH HCM (IBK HCM)'},
    {value:'KOOKMI', label:'NGAN HANG KOOKMIN - CN HA NOI'},
    {value:'VRB', label:'NGAN HANG LIEN DOANH VIET - NGA (VRB)'},
    {value:'AGB', label:'NGAN HANG NN VA PTNT VIETNAM(AGRIBANK)'},
    {value:'NHBHN', label:'NGAN HANG NONGHYUP CHI NHANH HA NOI(NHB HN)'},
    {value:'ACB', label:'NGAN HANG TMCP A CHAU (ACB)'},
    {value:'ABB', label:'NGAN HANG TMCP AN BINH (ABBANK)'},
    {value:'NASB', label:'NGAN HANG TMCP BAC A (NASB)'},
    {value:'VCPB', label:'NGAN HANG TMCP BAN VIET (VIETCAPITALBANK)'},
    {value:'BVB', label:'NGAN HANG TMCP BAO VIET (BVB)'},
    {value:'LPB', label:'NGAN HANG TMCP BUU DIEN LIEN VIET (LPB)'},
    {value:'VTB', label:'NGAN HANG TMCP CONG THUONG VIET NAM(VIETINBANK)'},
    {value:'PVCB', label:'NGAN HANG TMCP DAI CHUNG VIET NAM(PVCOMBANK)'},
    {value:'OJB', label:'NGAN HANG TMCP DAI DUONG (OCEANBANK)'},
    {value:'GPB', label:'NGAN HANG TMCP DAU KHI TOAN CAU (GPB)'},
    {value:'BIDV', label:'NGAN HANG TMCP DAU TU VA PHAT TRIENVIET NAM (BIDV)'},
    {value:'DAB', label:'NGAN HANG TMCP DONG A (DONGABANK)'},
    {value:'SEAB', label:'NGAN HANG TMCP DONG NAM A (SEABANK)'},
    {value:'MSB', label:'NGAN HANG TMCP HANG HAI VIET NAM (MSB)'},
    {value:'KLB', label:'NGAN HANG TMCP KIEN LONG(KIENLONGBANK)'},
    {value:'NAMABA', label:'NGAN HANG TMCP NAM A (NAMABANK)'},
    {value:'VCB', label:'NGAN HANG TMCP NGOAI THUONG VIET NAM(VIETCOMBANK)'},
    {value:'HDB', label:'NGAN HANG TMCP PHAT TRIEN TP.HCM (HDB)'},
    {value:'OCB', label:'NGAN HANG TMCP PHUONG DONG (OCB)'},
    {value:'MHB', label:'NGAN HANG TMCP PT NHA DONG BANG SONGCUU LONG'},
    {value:'MB', label:'NGAN HANG TMCP QUAN DOI (MB)'},
    {value:'NCB', label:'NGAN HANG TMCP QUOC DAN (NCB)'},
    {value:'VIB', label:'NGAN HANG TMCP QUOC TE VIB'},
    {value:'SCB', label:'NGAN HANG TMCP SAI GON (SCB)'},
    {value:'SHB', label:'NGAN HANG TMCP SAI GON - HA NOI (SHB)'},
    {value:'SGB', label:'NGAN HANG TMCP SAI GON CONG THUONG(SAIGONBANK)'},
    {value:'SACB', label:'NGAN HANG TMCP SAI GON THUONG TIN(SACOMBANK)'},
    {value:'TPB', label:'NGAN HANG TMCP TIEN PHONG (TPBANK)'},
    {value:'VAB', label:'NGAN HANG TMCP VIET A (VAB)'},
    {value:'VPB', label:'NGAN HANG TMCP VIET NAM THINH VUONG(VPBANK)'},
    {value:'VB', label:'NGAN HANG TMCP VIET NAM THUONG TIN(VIETBANK)'},
    {value:'PGB', label:'NGAN HANG TMCP XANG DAU PETROLIMEX(PG BANK)'},
    {value:'EIB', label:'NGAN HANG TMCP XUAT NHAP KHAU VIETNAM (EXIMBANK)'},
    {value:'IVB', label:'NGAN HANG TNHH INDOVINA'},
    {value:'CIMB', label:'NGAN HANG TNHH MTV CIMB (CIMB)'},
    {value:'HLB', label:'NGAN HANG TNHH MTV HONGLEONG VIETNAM'},
    {value:'HSBC', label:'NGAN HANG TNHH MTV HSBC (VIET NAM)'},
    {value:'VID', label:'NGAN HANG TNHH MTV PUBLIC VIET NAM(PBVN)'},
    {value:'SHBVN', label:'NGAN HANG TNHH MTV SHINHAN VIET NAM(SHBVN)'},
    {value:'SCVN', label:'NGAN HANG TNHH MTV STANDARDCHARTERED VIETNAM (SCVN)'},
    {value:'UOB', label:'NGAN HANG TNHH MTV UNITED OVERSEASBANK (UOB)'},
    {value:'WOO', label:'NGAN HANG WOORIBANK)'},
    {value:'CBBANK', label:'TM TNHH MTV Xay dung Viet Nam (CBBank)'},
];



export const BankMethod = [PaymentGateway.OnlineBanking, PaymentGateway.Bank, PaymentGateway.BankTransferQr, PaymentGateway.BankTransfer];
