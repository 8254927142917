import React, { useMemo, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
  Tag,
  TagLabel,
  Wrap,
  Grid,
  GridItem,
  Flex,
  Box,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';

import InputController from 'components/Form/InputController';
import SelectController from 'components/Form/SelectController';
import { useCreateWebsiteMutation, useUpdateWebsiteMutation } from 'services/website';
import { AdUnitsOptions, TabWebsiteAction, URL_REGEX } from 'constants/common';
import { ImProfile } from 'react-icons/im';
import { FiEdit } from 'react-icons/fi';
import VTcontent from 'components/Tab/VerticalContent';
import DetailWebsite from './DetailWebsite';
import { mappingOptionSelect } from 'utils/mapping';
import TableDetailWebsite from './TableDetail';

const sideMenu = {
  data: [
    {
      infor: {
        tab: TabWebsiteAction.Detail,
        name: TabWebsiteAction.Detail,
        iconMenu: <ImProfile />,
      },
    },
    {
      infor: {
        tab: TabWebsiteAction.InforAds,
        name: TabWebsiteAction.InforAds,
        iconMenu: <ImProfile />,
      },
    },
    {
      infor: {
        tab: TabWebsiteAction.Update,
        name: TabWebsiteAction.Update,
        iconMenu: <FiEdit />,
      },
    },
  ],
};

const CreateWebsite = ({ isOpen, editWebsiteDetail, onClose, categories, refetch, handelUpdateBannerWebsite, handleAbout }) => {
  const cancelRef = React.useRef();
  const toast = useToast();
  const createWebsite = useCreateWebsiteMutation();
  const updateWebsite = useUpdateWebsiteMutation();
  const [activeTabId, setActiveTabId] = useState(TabWebsiteAction.Detail);

  function handleClickButton(tab) {
    setActiveTabId(tab);
  }

  const categoryEdit = useMemo(() => {
    return categories.find(item => item.value === editWebsiteDetail?.categoryId?._id);
  }, [categories, editWebsiteDetail?.categoryId]);

  const schema = yup.object().shape({
    name: yup.string().required('Vui lòng nhập tên'),
    url: yup.string().matches(URL_REGEX, 'Đường dẫn không hợp lệ').required('Vui lòng nhập đường dẫn'),
    adUnits: yup
      .array()
      .min(1, 'Chọn ít nhất 1 đơn vị quảng cáo')
      .of(
        yup.object().shape({
          label: yup.string().required(),
          value: yup.string().required(),
        })
      )
      .required('Vui lòng chọn đơn vị quảng cáo'),
    categoryId: yup.object().nullable().required('Vui lòng chọn group'),
  });
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: editWebsiteDetail?.name || '',
      url: editWebsiteDetail?.url || '',
      adUnits: editWebsiteDetail?.adUnit
        ? editWebsiteDetail?.adUnit?.map(adUnitItem => {
            const adUnitExists = AdUnitsOptions?.find(item => item?.value === adUnitItem);

            return adUnitExists;
          })
        : undefined,
      categoryId: categoryEdit || undefined,
    },
  });

  const onSubmit = values => {
    if (!isEmpty(editWebsiteDetail)) {
      updateWebsite.mutate(
        {
          ...values,
          url: values?.url,
          categoryId: values?.categoryId?.value,
          adUnits: values?.adUnits?.map(item => item?.value),
          websiteId: editWebsiteDetail?._id,
        },
        {
          onSuccess: () => {
            toast({
              title: 'Đã chỉnh sửa thành công.',
              status: 'success',
              duration: 9000,
              isClosable: true,
            });
            refetch();
            onClose();
          },
        }
      );
    } else {
      createWebsite.mutate(
        { ...values, url: values?.url, categoryId: values?.categoryId?.value, adUnits: values?.adUnits?.map(item => item?.value) },
        {
          onSuccess: () => {
            toast({
              title: 'Đã tạo thành công.',
              status: 'success',
              duration: 9000,
              isClosable: true,
            });
            refetch();
            onClose();
          },
        }
      );
    }
  };

  return (
    <>
      <AlertDialog motionPreset="slideInBottom" leastDestructiveRef={cancelRef} onClose={onClose} isOpen={isOpen} isCentered size="4xl">
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader textTransform="uppercase">{`${
            isEmpty(editWebsiteDetail) ? 'Tạo thông tin Website' : 'Thông tin Website'
          }`}</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <Wrap w={'100%'}>
              <Grid w={'100%'} gridTemplateColumns={'1fr'} gap={6}>
                <GridItem w="100%">
                  <Flex w={'100%'} bg={'white'} borderRadius={4}>
                    <Flex gap={'10px'} w={'100%'} alignItems={'center'} justifyContent={'space-between'}>
                      {sideMenu.data.map((item, index) => (
                        <li key={index} style={{ listStyle: 'none', textAlign: 'left' }}>
                          <Button
                            flex={'auto'}
                            gap={'10px'}
                            onClick={() => handleClickButton(item.infor.tab)}
                            bg={activeTabId === item.infor.tab ? 'gray.100' : 'transparent'}
                            style={activeTabId === item.infor.tab ? { color: '#3182ce' } : { color: '#8892b0' }}
                          >
                            {item.infor.iconMenu}
                            {item.infor.name}
                          </Button>
                        </li>
                      ))}
                    </Flex>
                  </Flex>
                </GridItem>
                <GridItem
                  w={'100%'}
                  // minHeight={"400px"}
                  // overflowY={activeTabId === TabFeatureManagement.DetailInformation && userDetail?.groups?.length > 0 ? "hidden" : ""}
                  // overflowX={activeTabId === TabFeatureManagement.DetailInformation && userDetail?.groups?.length > 0 ? "scroll" : ""}
                  // className='custom-scrollbar-track'
                >
                  <VTcontent key={TabWebsiteAction.Detail} index={TabWebsiteAction.Detail} activeTabId={activeTabId}>
                    <DetailWebsite editWebsiteDetail={editWebsiteDetail} categories={categories} refetch={refetch} onClose={onClose} />
                  </VTcontent>
                  <VTcontent key={TabWebsiteAction.InforAds} index={TabWebsiteAction.InforAds} activeTabId={activeTabId}>
                    {/* <TableDetailWebsite editWebsiteDetail={editWebsiteDetail} categories={categories} refetch={refetch} onClose={onClose} /> */}
                    <TableDetailWebsite
                      website={editWebsiteDetail}
                      categories={categories}
                      onClose={onClose}
                      handelUpdateBannerWebsite={handelUpdateBannerWebsite}
                      handleAbout={handleAbout}
                    />
                  </VTcontent>
                  <VTcontent key={TabWebsiteAction.Update} index={TabWebsiteAction.Update} activeTabId={activeTabId}>
                    <Box w={'100%'}>
                      <form>
                        <InputController control={control} name="name" label="Tên" isRequired />
                        <InputController styleContainer={{ pt: '4' }} control={control} name="url" label="Đường dẫn" isRequired />
                        <SelectController
                          styleInput={{
                            paddingTop: 0,
                          }}
                          styleContainer={{ pt: '4' }}
                          control={control}
                          name="categoryId"
                          label="Danh mục website"
                          isRequired
                          options={categories}
                        />
                        <SelectController
                          styleContainer={{ pt: '4' }}
                          control={control}
                          name="adUnits"
                          label="Đơn vị quảng cáo"
                          placeholder="Chọn"
                          isRequired
                          options={AdUnitsOptions}
                          isMulti
                          extendsComponent={
                            !isEmpty(editWebsiteDetail) && (
                              <Tag borderRadius="full" variant="solid" colorScheme="blue">
                                <TagLabel lineHeight={'normal'}>{editWebsiteDetail?.adUnit?.length}</TagLabel>
                              </Tag>
                            )
                          }
                        />
                      </form>
                      <Flex justifyContent={'end'} mt={10}>
                        <Button ref={cancelRef} onClick={onClose}>
                          Hủy
                        </Button>
                        <Button colorScheme="blue" ml={3} onClick={handleSubmit(onSubmit)}>
                          {!isEmpty(editWebsiteDetail) ? 'Cập nhập' : 'Tạo'}
                        </Button>
                      </Flex>
                    </Box>
                  </VTcontent>
                </GridItem>
              </Grid>
            </Wrap>
          </AlertDialogBody>
          <AlertDialogFooter></AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default CreateWebsite;
