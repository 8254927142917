import React from 'react';
import { Flex } from '@chakra-ui/react';

import { useUserState } from 'context/UserContext';
import VerticalTab from 'components/VerticalTab/VerticalTab';
import { ImProfile } from 'react-icons/im';
import { IoKey } from 'react-icons/io5';
import { RiMoneyDollarBoxLine } from 'react-icons/ri';
import { TabFeatureProfile } from 'constants/common';

let sideMenu = {
  data: [
    {
      infor: {
        tab: TabFeatureProfile.IdentityInfo,
        name: 'Thông tin cá nhân',
        iconMenu: <ImProfile />,
      },
    },
    {
      infor: {
        tab: TabFeatureProfile.ChangePassword,
        name: 'Đổi mật khẩu',
        iconMenu: <IoKey />,
      },
    },
    {
      infor: {
        tab: TabFeatureProfile.Withdraw,
        name: "Rút tiền",
        iconMenu: <RiMoneyDollarBoxLine />,
      }
    },
    {
      infor: {
        tab: TabFeatureProfile.HistoryWithdraw,
        name: "Lịch sử rút tiền",
        iconMenu: <RiMoneyDollarBoxLine />,
      }
    },
    // {
    //   infor: {
    //     tab: TabFeatureProfile.Rechange,
    //     name: "Nạp tiền",
    //     iconMenu: <RiMoneyDollarBoxLine />,
    //   }
    // },
  ],
};

function Profile() {
  const { isAuthenticated } = useUserState();

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
      {!!isAuthenticated ? (
        <>
          <VerticalTab data={sideMenu.data} />
        </>
      ) : (
        ''
      )}
    </Flex>
  );
}

export default Profile;
