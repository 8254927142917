import { useMutation, useQuery } from '@tanstack/react-query';
import { ROOT_API } from 'constants/common';
import { request } from 'utils/request';

export const getBanks = (params) => request.get(ROOT_API + '/api/v1/transactions/banks', { params });
export const getMyHistoryTransactions = (params) => request.get(ROOT_API + '/api/v1/my-histories', { params });
export const getHistoryWithdraw = (params) => request.get(ROOT_API + '/api/v1/withdraw', { params });
export const createTransaction = data => request.post(ROOT_API + '/api/v1/transactions/create', data);
export const withDraw = data => request.post(ROOT_API + '/api/v1/publisher/request-withdraw', data);
export const createPayout = data => request.post(ROOT_API + '/api/v1/transactions/create-payout', data);


// Mutation
export const useCreateTransactionMutation = () => useMutation({ mutationFn: createTransaction });
export const useWithdrawMutation = () => useMutation({ mutationFn: withDraw });
export const useCreatePayoutMutation = () => useMutation({ mutationFn: createPayout });

// Query
export const useQueryGetBanks = (params = {}, options = {}) => useQuery({ queryKey: ['getBanks', params], queryFn: () => getBanks(params), ...options });
export const useQueryGetMyHistoryTransactions = (params = {}, options = {}) => useQuery({ queryKey: ['getMyHistoryTransactions', params], queryFn: () => getMyHistoryTransactions(params), ...options });
export const useQueryListHistoryWithdraw = (params = {}, options = {}) => useQuery({ queryKey: ['GET_HISTORY_WITHDRAW', params], queryFn: () => getHistoryWithdraw(params), ...options });

