import React, { useMemo } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
  Tag,
  TagLabel,
  Text,
  Box,
  Flex,
  Tooltip,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';

import InputController from 'components/Form/InputController';
import SelectController from 'components/Form/SelectController';
import { useCreateWebsiteMutation, useUpdateWebsiteMutation } from 'services/website';
import { AdUnitsOptions, URL_REGEX } from 'constants/common';

const DetailWebsite = ({ editWebsiteDetail, categories, onClose, refetch }) => {
  const cancelRef = React.useRef();
  const toast = useToast();
  const createWebsite = useCreateWebsiteMutation();
  const updateWebsite = useUpdateWebsiteMutation();

  const categoryEdit = useMemo(() => {
    return categories.find(item => item.value === editWebsiteDetail?.categoryId?._id);
  }, [categories, editWebsiteDetail?.categoryId]);

  const schema = yup.object().shape({
    name: yup.string().required('Vui lòng nhập tên'),
    url: yup.string().matches(URL_REGEX, 'Đường dẫn không hợp lệ').required('Vui lòng nhập đường dẫn'),
    adUnit: yup.object().nullable().required('Vui lòng chọn trạng thái'),
    categoryId: yup.object().nullable().required('Vui lòng chọn group'),
  });
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: editWebsiteDetail?.name || '',
      url: editWebsiteDetail?.url || '',
      adUnit:
        {
          label: editWebsiteDetail?.adUnit,
          value: editWebsiteDetail?.adUnit,
        } || undefined,
      categoryId: categoryEdit || undefined,
    },
  });

  const onSubmit = values => {
    if (!isEmpty(editWebsiteDetail)) {
      updateWebsite.mutate(
        {
          ...values,
          url: values?.url,
          categoryId: values?.categoryId?.value,
          adUnit: values?.adUnit?.value[0],
          websiteId: editWebsiteDetail?._id,
        },
        {
          onSuccess: () => {
            toast({
              title: 'Đã chỉnh sửa thành công.',
              status: 'success',
              duration: 9000,
              isClosable: true,
            });
            refetch();
          },
        }
      );
    } else {
      createWebsite.mutate(
        { ...values, url: values?.url, categoryId: values?.categoryId?.value, adUnit: values?.adUnit?.value },
        {
          onSuccess: () => {
            toast({
              title: 'Đã tạo thành công.',
              status: 'success',
              duration: 9000,
              isClosable: true,
            });
            refetch();
          },
        }
      );
    }
  };

  return (
    <>
      <Box flex flexDir="column">
        <Flex gap={6} mt={4}>
          <Text fontWeight={600} minWidth={150}>
            Tên:
          </Text>
          <Text>{editWebsiteDetail?.name}</Text>
        </Flex>
        <Flex gap={6} mt={4}>
          <Text fontWeight={600} minWidth={150}>
            Đường dẫn:
          </Text>
          <Text wordBreak="break-all">{editWebsiteDetail?.url}</Text>
        </Flex>
        <Flex gap={6} mt={4}>
          <Text fontWeight={600} minWidth={150}>
            Loại:
          </Text>
          <Text>{editWebsiteDetail?.categoryId?.name}</Text>
        </Flex>
        {/* <Flex gap={6} mt={4}>
                <Text fontWeight={600} minWidth={150}>
                  Phê duyệt:
                </Text>
                <Text>{editWebsiteDetail?.isApprove ? 'Đã phê duyệt' : 'Chưa phê duyệt'}</Text>
              </Flex> */}
        <Flex gap={6} mt={4}>
          <Text fontWeight={600} minWidth={150}>
            Trạng thái:
          </Text>
          <Text>{editWebsiteDetail?.status}</Text>
        </Flex>
        <Flex gap={6} mt={4}>
          <Text fontWeight={600} minWidth={150}>
            Đơn vị quảng cáo:
          </Text>
          <Flex flexWrap={'wrap'}>
            {editWebsiteDetail?.adUnit.map((item, index) => (
              <Text key={index}>{item}, </Text>
            ))}
          </Flex>
        </Flex>
        <Flex justifyContent={'end'} mt={10}>
          <Button ref={cancelRef} onClick={onClose}>
            Hủy
          </Button>
        </Flex>
      </Box>
    </>
  );
};

export default DetailWebsite;
