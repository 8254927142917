import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Flex,
  IconButton,
  Text,
  useClipboard,
} from '@chakra-ui/react';
import React from 'react';
import InputController from 'components/Form/InputController';
import { useForm } from 'react-hook-form';
import { GetCodeNativeFormValidate } from 'utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import capitalize from 'lodash/capitalize';
import { FaCopy } from 'react-icons/fa';
import { toast } from 'components/Toast';
import { renderScript } from 'utils/helpers';

const AboutAdUnit = ({ website, isOpen, onClose }) => {
  const cancelRef = React.useRef();

  const { control } = useForm({
    resolver: yupResolver(GetCodeNativeFormValidate),
    defaultValues: {},
  });

  const { onCopy } = useClipboard(renderScript(website.banner));

  const handleClickCopy = () => {
    onCopy();
    toast.showMessageSuccess('Đã sao chép.');
  };

  return (
    <AlertDialog motionPreset="slideInBottom" leastDestructiveRef={cancelRef} onClose={onClose} isOpen={isOpen} isCentered size="4xl">
      <AlertDialogOverlay />
      <AlertDialogContent padding={6} marginX={'10%'}>
        <AlertDialogHeader>
          <Text gap={2} fontSize={'20px'}>
            Thông tin Quảng cáo
          </Text>
        </AlertDialogHeader>
        <AlertDialogCloseButton margin={10} fontSize={16} />
        <AlertDialogBody overflowX="auto" ontSize={'16px'}>
          <form>
            <Flex color="white" marginTop={6} gap={10}>
              <Flex flex="1" flexDirection={'column'} color={'#121F4B'} textColor={'#222222'} fontSize={14}>
                <InputController
                  defaultValue={website.banner.name + '_1'}
                  control={control}
                  name="color"
                  label="Tên"
                  styleBoxInput={{ flex: 1 }}
                  styleLabel={{ marginTop: '10px' }}
                  readOnly
                />
                {website?.banner?.script && (
                  <Flex mt={6} alignItems={'center'} gap={8}>
                    <Text width={20} color={'#727272'}>
                      Script:
                    </Text>
                    <Text mr={5}>{renderScript(website.banner)}</Text>
                    <IconButton><FaCopy cursor={'pointer'} onClick={handleClickCopy} /></IconButton>
                  </Flex>
                )}
                <Flex mt={6}>
                  <Text width={20} color={'#727272'}>
                    Loại:
                  </Text>
                  <Text>{website.banner.adUnit}</Text>
                </Flex>
                <Flex mt={6}>
                  <Text width={20} color={'#727272'}>
                    Trạng thái:
                  </Text>
                  <Text>{capitalize(website.banner.status)}</Text>
                </Flex>
                <Flex mt={3}>
                  <Text minW={20} color={'#727272'}>
                    Website:
                  </Text>
                  <Text wordBreak="break-all">{website.website.url}</Text>
                </Flex>
                <Flex justifyContent={'flex-end'} gap={4}>
                  {/* <Button marginTop={6} float={'right'} onClick={onClose}>
                    Đóng
                  </Button> */}
                  {/* <Button colorScheme="blue" marginTop={6} float={'right'} onClick={handleSubmit(onSubmit)}>
                    Lưu
                  </Button> */}
                </Flex>
              </Flex>
            </Flex>
            <br />
          </form>
        </AlertDialogBody>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default AboutAdUnit;
