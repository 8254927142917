import { Flex, IconButton, Td, Text, Tooltip, Tr, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { EditIcon } from '@chakra-ui/icons';
import { formatDate } from 'utils/helpers';
import { STATUS_TICKET, convertEnumToLabelOption } from 'constants/common';

function Row({ user, isLast, handelUpdateTicket }) {
  const textColor = useColorModeValue('gray.500', 'white');
  const titleColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  const handleRowClick = () => {
    handelUpdateTicket(user);
  };

  return (
    <Tr>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null} pl="0px">
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold" isTruncated>
            {user?.type?.name || '--'}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold" isTruncated>
            {user?.subject}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null} maxW="400px">
        <Flex direction="column">
          <Tooltip label={user?.content} placement="top">
            <Text fontSize="md" color={textColor} fontWeight="bold" noOfLines={2}>
              {user?.content}
            </Text>
          </Tooltip>
        </Flex>
      </Td>
      <Td minWidth={{ sm: '150px' }} borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold" textTransform="uppercase">
            {user?.status}
          </Text>
        </Flex>
      </Td>
      <Td minWidth={{ sm: '100px' }} pl="0px" borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Flex direction="column">
            <Text fontSize="md" color={textColor} minWidth="100%" fontWeight="bold">
              {formatDate(user?.createdAt, 'DD-MM-YYYY')}
            </Text>
          </Flex>
        </Flex>
      </Td>
      <Td minWidth={{ sm: '150px' }} borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {formatDate(user?.updatedAt, 'DD-MM-YYYY')}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <IconButton
          p={2}
          bg="transparent"
          onClick={() => {
            handleRowClick();
          }}
        >
          <EditIcon />
        </IconButton>
      </Td>
    </Tr>
  );
}

export default Row;
