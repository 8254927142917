import React, { useState } from 'react';
import { Flex, Table, Tbody, Th, Thead, Tr, Td, Box, Card as CardChakra, Card, useColorModeValue } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import {  useQueryListHistoryWithdraw } from 'services/transaction';
import { toast } from 'components/Toast';
import Row from './components/Row';
import Pagination from 'components/Pagination/Pagination';
export const initialFilter = {
  pageSize: 10,
  pageIndex: 0,
};
function HistoryWithdraw({data,isLoading}) {
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  // const [filter, setFilter] = useState(initialFilter);
  // const { data, isLoading, refetch } = useQueryListHistoryWithdraw({...filter});
  return (
    <Card p="16px" mb="24px" bg="#fff">
      <Table variant="simple" color={textColor} overflowX="auto" mt={10}>
        <Thead>
          <Tr my=".8rem" pl="0px" color="gray.400">
            <Th pl="0px" borderColor={borderColor} color="gray.400">
              Mã giao dịch
            </Th>
            <Th borderColor={borderColor} color="gray.400">
              Số tiền
            </Th>
            <Th borderColor={borderColor} color="gray.400">
             Hình thức
            </Th>
            <Th borderColor={borderColor} color="gray.400">
              Trạng thái
            </Th>
            <Th borderColor={borderColor} color="gray.400">
              Ngày/giờ
            </Th>
            <Th borderColor={borderColor} color="gray.400">
              
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.data?.map((row, index, arr) => {
            return <Row key={row._id} data={row} isLast={index === arr.length - 1 ? true : false} />;
          })}
          {isEmpty(data?.data) && !isLoading && (
            <Tr>
              <Td colSpan="8">
                <Box textAlign="center" height="200px" pt="24px">
                  Không có dữ liệu
                </Box>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      <Flex justifyContent={'flex-end'}>
        <Pagination
          page={data?.pagination?.page}
          pageLength={data?.pagination?.pageSize}
          totalRecords={data?.pagination?.count}
          onPageChange={(page, pageLength) => {
            setFilter({
              ...filter,
              pageSize: pageLength,
              pageIndex: page - 1,
            });
          }}
        />
      </Flex>
    </Card>
  );
}

export default HistoryWithdraw;
