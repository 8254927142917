import { Button, Flex, Td, Text, Tr, useColorModeValue } from '@chakra-ui/react';
import { IS_VERIFIED_INFO } from 'constants/common';
import React from 'react';
import { useCreatePayoutMutation } from 'services/transaction';
import { currencyFormat, formatDate } from 'utils/helpers';

function Row({ data, isLast }) {
  const textColor = useColorModeValue('gray.500', 'white');
  const titleColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const createPayoutMutation = useCreatePayoutMutation();
  const handleCreatePayout = transactionId => {
    createPayoutMutation.mutate(
      { transactionId },
      {
        onSuccess: res=> {
          toast({
            title: 'Rút tiền thành công.',
            status: 'success',
            duration: 9000,
            isClosable: true,
          });
        },
        onError:res=>{
          // toast({
          //   title: res?.message,
          //   status: 'error',
          //   duration: 9000,
          //   isClosable: true,
          // });
        }
      }
    );
  };
  return (
    <Tr>
      <Td pl="0px" borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Flex direction="column">
            <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
              {data?.order_no}
            </Text>
          </Flex>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {currencyFormat(data?.amount)}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {data?.channel}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text
            fontSize="md"
            color={
              data?.status === IS_VERIFIED_INFO.APPROVED ? 'green.500' : data?.status === IS_VERIFIED_INFO.REJECTED ? 'red.500' : textColor
            }
            fontWeight="bold"
          >
            {data?.status === IS_VERIFIED_INFO.APPROVED ? 'ĐÃ DUYỆT' : data?.status === IS_VERIFIED_INFO.REJECTED ? 'TỪ CHỐI' : 'CHỜ DUYỆT'}
          </Text>
        </Flex>
      </Td>
      {/* <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
          {data?.contactUsername}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
          {data?.contactChannel}
          </Text>
        </Flex>
      </Td> */}

      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {formatDate(data?.updatedAt, 'MM/DD/YYYY h:mm A')}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          {data?.status === IS_VERIFIED_INFO.APPROVED && (
            <Button
              colorScheme="blue"
              // mt={4}
              onClick={() => handleCreatePayout(data?._id)}
            >
              Rút tiền
            </Button>
          )}
            {data?.status === IS_VERIFIED_INFO.SUCCESS && (
            <Text
              color="green.500"
              fontWeight={700}
            >
              Thành công
            </Text>
          )}
        </Flex>
      </Td>
    </Tr>
  );
}

export default Row;
